import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux';
import axios from 'axios';
import { setStatusMessage } from '../redux/store';
import i18next from 'i18next';
import StyledTable, {
    TableHeaderCell, TableHeaderElement,
    TableBodyElement, TableBodyCell, TableBodyRow
} from '../components/StyledTable';

export function CompanyInfo() {
    let [companyData, setCompanyData] = useState({});

    function msgError(msg) {
        dispatch(setStatusMessage({ msg: msg, error: true }));
    }
    function msgSuccess(msg) {
        dispatch(setStatusMessage({ msg: msg, error: false }));
    }

    const dispatch = useDispatch();    
    useEffect(() => {
        axios.get("/api/user/company")
        .then((d) => { console.log(d); setCompanyData(d.data)})
        .catch((e) => { 
            console.error(e); 
            msgError(i18next.t("Failed to get company info"));
        })
    }, [false]);

    return (
        <StyledTable>
            <TableHeaderElement>
                {["Company", "Device count", "Admin"].map((name) =>
                <TableHeaderCell key={name}>
                {i18next.t(name)}
            </TableHeaderCell>
                )}
            </TableHeaderElement>
            <TableBodyElement>
                {companyData.name && 
            <TableBodyRow>
                <TableBodyCell>{companyData.name}</TableBodyCell>
                <TableBodyCell>{companyData.devices.filter(d => !d.ends).length}</TableBodyCell>
                <TableBodyCell>{companyData.admin}</TableBodyCell>
                </TableBodyRow>
}


            </TableBodyElement>
        </StyledTable>        
    );
}