/** @file
 * @brief pipeline processor set when portOpen happens and closed when connection closes
 * pipelines are used for sending multiple commands in a row to device
 * currently called from sensorData.jsx
 */
/** @brief current pipeline functions */
let pipelineCurrent = [];
/** @brief setInveral timer that calls pipelineProcess */
let pipelineProcessor = null;

let pipelineCommand = "";
let pipelineCommandStarted = null;
const defaultTimeoutMs = 300;
const pipelineIntervalMs = 50;
let pipelineCommandTimeMs = defaultTimeoutMs;

export function pipelineSet(arr) {
    if (pipelineCurrent.length > 0)
        return;
    if (!pipelineProcessor)
        pipelineStart();

    pipelineCurrent = arr;
}

export function pipelineStop() {
    pipelineCurrent = [];
    clearInterval(pipelineProcessor);
    pipelineProcessor = null;
    pipelineCommand = "";
    pipelineCommandStarted = null;
}

export function pipelineCommandCheck(cmdDone) {
    if (cmdDone === pipelineCommand) {
        pipelineCommand = "";
        pipelineCommandStarted = null;
    }
}

export function pipelineStart() {
    if (pipelineProcessor)
        return;
    pipelineProcessor = setInterval(pipelineProcess, pipelineIntervalMs);
}

export function pipelineTimeout() {
    pipelineStop();
}

export function pipelineProcess() {
    if (!pipelineCurrent.length)
        return;
    if (pipelineCommand.length > 0) {
        if (new Date() - pipelineCommandStarted > pipelineCommandTimeMs) {
            pipelineTimeout();
        }
    }
    else {
        let cmd = pipelineCurrent.shift();
        pipelineCommand = cmd.cmd;
        pipelineCommandStarted = new Date();
        if (cmd.timeout)
            pipelineCommandTimeMs = cmd.timeout;
        else
            pipelineCommandTimeMs = defaultTimeoutMs;

        cmd.fn();
    }
}
