import { React, useState, useEffect } from "react"
import HituLogo from "../assets/images/HituLogo.svg"
import HomeIcon from "../assets/icons/home-icon.svg"
import SidebarIcon from "../assets/icons/sidebar-menu-icon.svg"
import { Link } from "react-router-dom"
import { connect } from 'react-redux';
import { useDispatch } from "react-redux";
import { tokenClear } from "../redux/store";
import i18next from "i18next";
import { useNavigate, useLocation } from 'react-router-dom';


function Navbar2(props) {

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [menuOpen, setMenuOpen] = useState(false)

  function NavbarLinkButton({ children, toLink }) {
    return (
      <Link to={toLink}>
        <button
          style={{ textTransform: "capitalize", backgroundColor: useLocation().pathname === `/${toLink}` ? "#252525" : "" }}
          className=" hover:bg-cyan-900 text-cyan-400 font-bold h-12 w-full"
          onClick={() => setMenuOpen(false)}
        >
          {children}
        </button>
      </Link>
    )
  }

  console.log("Current location: ", useLocation().pathname)

  return (
    <>
      <div className="sticky top-0 flex justify-between bg-black h-14 z-50">
        <div className="ml-5 my-auto">
        <Link to={``}>
          <img src={HomeIcon} alt="Takes you to the frontpage" width={24}></img>
          </Link>
        </div>

        <div className="p-2 my-auto">
          <img src={HituLogo} alt="Hitu logo" width={150}></img>
        </div>
        <div className="mr-5 my-auto">
          <button onClick={() => setMenuOpen(!menuOpen)}>
            <img
              src={SidebarIcon}
              alt="Opens up the sidebar menu"
              width={28}
            ></img>
          </button>
        </div>
      </div>
      {menuOpen && <div className="fixed right-0 top-14 w-2/12 h-full bg-black z-50">
        <div
          className="w-full h-full flex-col"
          style={{ backgroundColor: "#121212" }}
        >
          <NavbarLinkButton toLink={"dashboard"}>
            {i18next.t("Live")}
          </NavbarLinkButton>

          <NavbarLinkButton toLink={"live-data"}>
            {i18next.t("Sensor Configuration")}
          </NavbarLinkButton>

          <NavbarLinkButton toLink={"support"}>
            {i18next.t("Support")}
          </NavbarLinkButton>

          {props.loggedIn && (
            <NavbarLinkButton toLink={"profile"}>
              {i18next.t("Profile")}
            </NavbarLinkButton>
          )}
          {props.loggedIn && (
            <NavbarLinkButton toLink={"devices"}>
              {i18next.t("Devices")}
            </NavbarLinkButton>
          )}
          {!props.loggedIn && (
            <NavbarLinkButton toLink={"login"}>
            {i18next.t("Login")}
          </NavbarLinkButton>
            
          )}
          {props.admin && (
            <>
              <NavbarLinkButton toLink={"usermanagement"}>
                {i18next.t("Users")}
              </NavbarLinkButton>
              <NavbarLinkButton toLink={"registration"}>
                {i18next.t("register")}
              </NavbarLinkButton>
            </>
          )}
          {props.admin && (
             <NavbarLinkButton toLink={"company"}>
             {i18next.t("Company")}
           </NavbarLinkButton>
            
          )}
          {props.loggedIn && (
            
            <button
              style={{ textTransform: "capitalize" }}
              className=" hover:bg-cyan-900 text-cyan-400 font-bold py-2 px-4 h-12 w-full"
              onClick={() => {
                dispatch(tokenClear())

                navigate("/")
              }}
            >
              {i18next.t("logout")}
            </button>
          )}
        </div>
      </div>}
      
    </>
  )
}

function mapStateToProps(state) {
  return {
      loggedIn: state.token !== undefined,
      admin: state.admin || state.companyAdmin,
  };
}

export default connect(mapStateToProps)(Navbar2);

