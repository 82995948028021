import i18next from "i18next";
import axios from "axios";

let serialInstance = null;
let commandTimeout = null;
const baud = 115200;
const cmdEnd = "\r\n";
const encoder = new TextEncoder();
const decoder = new TextDecoder();
const cmdTimeout = 800; // ms
const tickProcessFrequency = 100; // ms

const linePrefixResponse = "@";
const linePrefixError = "!";
const linePrefixData = "#";

function log(...args) {
    console.log.apply(console.log, args);
}

function validateNumber(v) {
    return !(v === undefined || v === null || isNaN(v));
}

export let measTypes = [];
let fetchingPromise = null;
/** @brief default csv->json data that comes from device
 * fetched from backend so those values be defined in one place
 * this should be moved to redux
 */
export function initMeasTypes() {
    if (measTypes.length > 0)
        return Promise.resolve();
    if (fetchingPromise)
        return fetchingPromise;
    fetchingPromise = axios.get('/api/ui/measTypes').then((data) => {
        if (data && data.data && data.data.length > 0)
            measTypes = data.data;
            
        fetchingPromise = null;
    }).catch((e) => {
        console.error(e);
        fetchingPromise = null;
    });
    return fetchingPromise;
}




/**
 * parse Hitu csv data to json object
 * @param {*} csv 
 * @returns null if there is invalid data
 */
export function csvToJson(csv) {
    if (!csv || csv.length === 0)
        return null;

    let vals = csv.split(",");
    let ret = {};
    ret['time'] = new Date().toUTCString();

    for (let index in measTypes) {
        if (index > vals.length) {
            break;
        }

       // time should always be first but it's handled separately
       if (measTypes[index].name === 'time')
            continue;
       
       let valIndex = index - 1;

 
        switch (measTypes[index].valueType) {
            case 0: // int
                ret[measTypes[index].name] = parseInt(vals[valIndex]);
                if (!validateNumber(ret[measTypes[index].name])) {
                    log("invalid csv data", index, csv);
                    return null;
                }
                break;
            case 1: // float
                ret[measTypes[index].name] = parseFloat(vals[valIndex]);
                if (!validateNumber(ret[measTypes[index].name])) {
                    log("invalid csv data", index, csv);
                    return null;
                }
                break;
            case 2: // string
                ret[measTypes[index].name] = vals[valIndex];
                break;
        }
    }
    return ret;
}

// store data to postgre and influx
export function sendRowToBackend(json) {

}

function initSerial(ports) {

    // TODO init already selected device without user selection?
    // the port list might contain devices that are not actually connected?
    /*
    if (ports && ports.length > 0) {
        serialInstance.port = ports[0];
        log("getPort", serialInstance.port, serialInstance.port.getInfo());
        this.connectPort();
    }
    */

}

/** @brief get serial instance and initialize Serial singleton if needed
 * TODO: might need to support multiple instances?
 */
export function getSerial() {
    if (!serialInstance && navigator && "serial" in navigator) {
        serialInstance = new Serial();


        navigator.serial.addEventListener('connect', async (e) => {
            log("navigator connect", e);
            if (serialInstance) {
                await serialInstance.connected(e);
            }
        });

        navigator.serial.addEventListener('disconnect', async (e) => {
            log("navigator disconnect", e);
            if (serialInstance) {
                await serialInstance.disconnected(e);
            }
        });

        navigator.serial.getPorts().then((ports) => {
            initSerial(ports);
        });
    }
    return serialInstance;
}

let States = {
};

/** @brief State based Serial handler */
export class Serial {
    constructor() {
        // States populated dynamically to get the translations working
        // should init translations before this somehow?
        if (!States.Idle) {
            States.Idle = i18next.t("Idle");
            States.UpdatingPorts = i18next.t("UpdatingPorts");
            States.Active = i18next.t("Active");
            States.Closing = i18next.t("Closing");
            States.Selecting = i18next.t("Selecting");
            States.Forgetting = i18next.t("Forgetting");
            States.Error = i18next.t("Error");
        }
        // read measurement data for table / graph
        this.readRows = [];

        this.state = States.Idle;
        this.statePrevious = States.Idle;

        this.port = null;
        this.ports = [];
        // callbacks to front
        this.cbRead = null;
        this.portLock = null;
        this.readBuffer = "";
        this.readPromise = null;

        // used to track command timeout
        this.lastWriteCommand = null; // { cmd, args, done }
        this.tickProcess();
    }

    setState(st) {
        log("set state", st, States[st], States);
        this.statePrevious = this.state;
        this.state = st;
        if (this.cbStateChange) {
            this.cbStateChange({ state: States[st] });
            if (st === States.Active) {
                this.cbStateChange({ portOpen: true });
            }
            else if (this.statePrevious === States.Active) {
                this.cbStateChange({ portOpen: false });
            }

        }
    }

    // handle states, maybe bad style and should be done in events...
    // managing events with stuck port requires some kind of of looping regardless..
    async tickProcess() {
        let parent = this;
        if (this !== serialInstance) {
            log("quitting tick process");
        }
        if (!this.state)
            this.state = States.Idle;

        switch (this.state) {
            case States.Idle:
                break;
            case States.Closing:
                this.readPromise = null;
                if (this.port) {
                    // try to release locks and close the port
                    if (this.port.readable && this.port.readable.locked) {
                        if (this.portLock) {
                            try { this.portLock.releaseLock(); } catch (e) { console.error(e); }
                        }
                        try { await this.port.readable.cancel(); } catch (e) { console.error(e); }
                        try { await this.port.close(); } catch (e) { console.error(e); }
                        try { await this.port.forget(); } catch (e) { console.error(e); }
                        this.port = null;
                    }
                    else {
                        try { await this.port.close(); } catch (e) { }
                        try { await this.port.forget(); } catch (e) { console.error(e); }
                        this.port = null;
                    }
                }
                else {
                    this.setState(States.Idle);
                }
                break;
            case States.Error:
                if (this.port) {
                    try { this.port.close(); } catch (e) { console.error("close", e); }
                    this.readPromise = null;
                    this.port = null;
                    this.setState(States.Idle);
                }
                else {
                    this.setState(States.Idle);
                }
                break;
            case States.Active:
                if (!this.readPromise) {
                    if (!this.port.readable) {
                        this.setState(States.Closing);
                    }
                    else {
                        this.readPromise = this.reader();
                        this.readPromise.then((v) => {
                            if (v && v.length > 0) {
                                parent.processRead(v);
                            }
                            parent.readPromise = null;
                        }, (e) => {
                            log("read reject", e);
                            parent.readPromise = null;
                        });
                    }
                }
                break;
        }
        // 10 times in seconds, hitu sends data every minute so no need to run often
        // smaller timeout for more responsive state changes in UI
        setTimeout(async () => { await parent.tickProcess() }, tickProcessFrequency);
    }

    /**
     *  Register UI callbacks
     */
    register(cbRead, cbCmdResponse, cbStateChange) {
        this.cbRead = cbRead;
        this.cbStateChange = cbStateChange;
        this.cbCmdResponse = cbCmdResponse;

    }
    async close() {
        this.setState(States.Closing);
    }

    /** @brief parse uart input
     * buffer might not contain full line so buffer reads and process only after newline
     */
    processRead(txt) {
        for (let index in txt) {
            if (txt[index] === "\n") {
                if (this.readBuffer.length > 0) {
                    this.readRows.push(this.readBuffer);
                    log("read:", this.readBuffer);
                    //if response to write command
                    if (this.readBuffer.startsWith(linePrefixError)) {
                        if (this.cbStateChange) {
                            // error message from device
                            this.cbStateChange({ errorMsg: this.readBuffer.slice(1) });
                        }

                    }
                    else if (this.readBuffer.startsWith(linePrefixData)) {
                        if (this.cbRead)
                            this.cbRead(this.readBuffer.slice(1));
                    }
                    else if (this.readBuffer.startsWith(linePrefixResponse)) {
                        if (this.lastWriteCommand && !this.lastWriteCommand.done) {
                            this.lastWriteCommand.done = true;
                            const cmd = this.lastWriteCommand.cmd;
                            const args = this.lastWriteCommand.args;
                            // Set last command null to make it possible to send new command from callback
                            this.lastWriteCommand = null;
                            clearTimeout(commandTimeout);
                            if (this.cbCmdResponse) {
                                let line = this.readBuffer.slice(1);
                                line = line.trim();
                                let space = line.indexOf(" ");
                                let status = parseInt(line.substring(space + 1, space + 2));
                                this.cbCmdResponse(cmd, args, status, line.substring(space + 3));
                            }
                        }
                        else if (this.lastWriteCommand) {
                            console.error("Received response without command", this.readBuffer);
                            this.cbStateChange({ errorMsg: i18next.t("invalid response") });
                        }
                    }
                    else {
                        console.error("invalid line read", this.readBuffer);
                    }
                }
                this.readBuffer = "";
            }
            else {
                this.readBuffer += txt[index];
            }
        }
    }

    async fetchPorts() {
        if (this.state !== States.Idle) {
            log("can't fetch ports from state ", this.state);
            return;
        }
        this.setState(States.UpdatingPorts);
        let newPorts = await navigator.serial.getPorts();
        this.setState(States.Idle);
    }


    // issue with reading port that was closed and new created?
    async reader() {
        try {
            this.portLock = this.port.readable.getReader();
        } catch (e) {
            console.error("reader error", e, this.port);
            return null;
        }
        try {
            let { done, value } = await this.portLock.read();
            this.portLock.releaseLock();
            if (done) {
                log("reader done");
                return null;
            }
            let txt = decoder.decode(value);
            return txt;
        }
        catch (e) {
            log("read error", e);
            this.portLock.releaseLock();
            return null;
        }

    }

    /**
     * @brief Try to open selected port and initlialize callbacks
     */
    async connectPort() {
        let parent = this;
        if (!this.port) {
            log("connectPort, this.port null");
            return;
        }
        this.port.onconnect = function (e) {
            log("port connect", e);
        }

        this.port.ondisconnect = function (e) {
            log("port disconnect ", e);
            parent.setState(States.Closing);

        }
        if (this.port.readable) {
            console.log("port already open");
            if (this.cbStateChange)
                this.cbStateChange({ portOpen: true });

            this.setState(States.Active);
        }
        else {
            try {
                await this.port.open({ baudRate: baud });
                log("port open");
                if (this.cbStateChange)
                    this.cbStateChange({ portOpen: true });

                this.setState(States.Active);
            }
            catch (e) {
                console.error("open", e);
                await this.port.forget();
                this.port = null;
                this.setState(States.Error);
            }
        }
    }
    /**
     * get new port, user needs to give access to port before js can see the port
     */
    async getPort() {
        if (this.state !== States.Idle && this.state !== States.Error) {
            console.log("trying to get new port from state", this.state);
            return;
        }
        this.setState(States.Selecting);
        try {
            this.port = await navigator.serial.requestPort();
            log("getPort", this.port, this.port.getInfo());
            await this.connectPort();
        } catch (e) {
            console.error(e);
            this.setState(States.Idle);
        }
    }

    /**
     * get or set register command
    */
    reg(reg, v) {
        if (v === null || v === undefined) {
            this.writeCommand("get", reg);
        }
        else {
            this.writeCommand("set", reg, v);
        }
    }

    /**
     * Write command to uart if writer exists, command is appended with linefeed and newline
     */
    writeCommand(...args) {
        if (args.length === 0 || !this.port)
            return;

        if (this.lastWriteCommand) {
            log("old command pending, not sending");
            return;
        }

        let sendingCmd = args[0];
        let cmd = args.join(' ') + cmdEnd;
        log("write cmd:", cmd);
        try {
            let w = this.port.writable.getWriter();
            w.write(encoder.encode(cmd));
            w.releaseLock();
            // save the cmd and first argument
            this.lastWriteCommand = { cmd: sendingCmd, args: args.splice(1), done: false };
            let parent = this;

            commandTimeout = setTimeout(() => {
                if (parent.lastWriteCommand) {

                    if (!parent.lastWriteCommand.done) {
                        log("cmd timedout", this.lastWriteCommand.cmd);
                        if (this.cbStateChange) {
                            this.cbStateChange({ errorMsg: i18next.t("Time out") + ": " + this.lastWriteCommand.cmd });
                        }
                    }
                    parent.lastWriteCommand = null;
                }
            }, cmdTimeout);

        }
        catch (e) {
            console.error(e);
        }
    }

    async connected(e) {
        if (this.state == States.Idle) {
            this.port = e.target;
            await this.connectPort();

        }
    }

    disconnected(e) {
        log("disconnected", e);
    }


    cmdStart() {
        this.writeCommand("start");
    }
    cmdHelp() {
        this.writeCommand("help");
    }
    cmdStop() {
        this.writeCommand("stop");
    }
    cmdReset() {
        this.writeCommand("reset");
    }
    cmdUpdate() {
        this.writeCommand("update");
    }
    cmdSave() {
        this.writeCommand("save");

    }

    cmdLogin(password) {
        this.writeCommand("sulogin", password);
    }
    cmdLogout() {
        this.writeCommand("logout");
    }
}

