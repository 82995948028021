import { React, useRef } from "react"
import {
  MapContainer,
  TileLayer,
  Marker,
  Popup,
  useMapEvents,
} from "react-leaflet"
import markerIconPng from "../assets/icons/address-icon.png"
import { Icon } from "leaflet"
import "leaflet/dist/leaflet.css"
import i18next from "i18next"

export default function SensorMap({
  sensors,
  onHover = null,
  onSelect = null,
  mapHeight,
  mapWidth,
}) {
  //TODO: Switch these sensors to data from Influx instead of dummy data.

  const mapRef = useRef()

  function HandleFly(sensor) {
    if (mapRef.current && sensor.lat && sensor.lon) {
      const map = mapRef.current
      map.flyTo([sensor.lat, sensor.lon], 17)
    }

    if (onSelect) {
      onSelect(sensor)
    }
  }

  return (
    //Map container renders a live map with sensors marked on the map. Popups are customizable and the size of the map can be changed from the style tag.

    <div className="flex">
        <div
          className="text-cyan-500 overflow-y-auto border-2 border-black"
          style={{
            backgroundColor: "#24282B",
            position: "absolute",
            zIndex: 20,
            height: mapHeight
          }}
        >
          <h1 className="text-lg font-bold p-2">{i18next.t("Sensors")}</h1>
          {sensors.map((sensor, index) => (
            <div key={sensor.name}>
              <button
                onClick={() => HandleFly(sensor)}
                className="w-full p-2 text-left"
                style={{ backgroundColor: index % 2 === 0 ? "#4A4E52" : "" }}
              >
                <div className="flex">
                  <div>
                    <h3 className="text-gray-300 font-bold capitalize">
                      {sensor.label.length > 0 ? sensor.label : sensor.name}
                    </h3>
                  </div>
                  <div className="p-1">
                    {/* TODO: Implement the sensor color indicators */}
                    {/* <div className="h-2 w-2 bg-red-600 p-1"></div> */}
                  </div>
                </div>
              </button>
            </div>
          ))}
        </div>

      <MapContainer
        center={[60.4518, 22.2666]}
        zoom={13}
        zoomControl={false}
        scrollWheelZoom={true}
        style={{ height: mapHeight, width: mapWidth, zIndex: 0 }}
        ref={mapRef}
      >
        <TileLayer
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          url="https://api.mapbox.com/styles/v1/mapbox/navigation-night-v1/tiles/{z}/{x}/{y}?access_token=pk.eyJ1IjoiY2MtdmlsbGUiLCJhIjoiY2xmdGYyYmhoMDBiNTNkcDB5YzNxOW9wcyJ9.afjAFzh69h-pWXeEULqvoA"
        />
        {sensors
          .filter((v) => v.lat)
          .map((sensor) => (
            <Marker
              position={[sensor.lat, sensor.lon]}
              key={sensor.name}
              eventHandlers={{
                mouseover: async (e) => { if(onHover) { await onHover(e, sensor); } e.target.openPopup(); },
                mousedown: () => HandleFly(sensor),
                /* onClick: (event) => event.target.openPopup(), */
              }}
              icon={
                new Icon({
                  iconUrl: markerIconPng,
                  iconSize: [30, 30],
                  iconAnchor: [20, 0],
                })
              }
            >
              <Popup>
                <div className="font-bold text-lg text-cyan-600">
                {sensor.label.length > 0 ? sensor.label : sensor.name}
                </div>
                <table style={{minWidth: 80}}>
                  <thead >
                  </thead>
                  <tbody className="py-0.5">
                    <tr>
                      <td className="font-bold">PM1</td>
                      <td className="text-cyan-700">{sensor.latest ? parseFloat(sensor.latest.s1).toFixed(1) : "N/A"}</td>
                    </tr>
                    <tr>
                      <td className="font-bold">PM2.5</td>
                      <td className="text-cyan-700">{sensor.latest ? parseFloat(sensor.latest.s2).toFixed(1) : "N/A"}</td>
                    </tr>
                    <tr>
                      <td className="font-bold">PM10</td>
                      <td className="text-cyan-700">{sensor.latest ? parseFloat(sensor.latest.s3).toFixed(1) : "N/A"}</td>
                    </tr>
                  </tbody>
                </table>
                µg/m³
              </Popup>
            </Marker>
          ))}
      </MapContainer>
    </div>
  )
}
