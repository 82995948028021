import React from "react"
import { Link } from "react-router-dom"
import { connect } from 'react-redux';
import { useDispatch } from "react-redux";
import { tokenClear } from "../redux/store";
import i18next from "i18next";
import { useNavigate } from 'react-router-dom';

function NavbarLinkButton({ children, toLink }) {
    return (<Link to={toLink}>
        <button
            style={{ "textTransform": "capitalize" }}
            className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full">
            {children}
        </button>
    </Link>);
}

function Navbar(props) {
    const dispatch = useDispatch();

    const navigate = useNavigate();
    return (
        <div className="flex justify-center bg-slate-800">
            <div className="w-4/5">
                <div className="flex justify-between">
                    <div className="">
                        <Link to={``}>

                            <button
                                style={{ "textTransform": "capitalize" }}
                                className=" hover:bg-slate-700 text-3xl text-white font-bold py-2 px-4 h-full">
                                Senhitu
                            </button>
                        </Link>
                    </div>
                    <div className="">
                        <Link to={"dashboard"}>
                            <button
                                style={{ "textTransform": "capitalize" }}
                                className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full">
                                {i18next.t("live")}
                            </button>
                        </Link>

                        <Link to={"live-data"}>
                            <button
                                style={{ "textTransform": "capitalize" }}
                                className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full">
                                {i18next.t("sensor configuration")}
                            </button>
                        </Link>

                        <Link to={`support`}>
                            <button
                                style={{ "textTransform": "capitalize" }}
                                className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full">
                                {i18next.t("support")}
                            </button>
                        </Link>
                        {props.loggedIn &&
                            <Link to={"profile"}>
                                <button
                                    style={{ "textTransform": "capitalize" }}
                                    className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full">
                                    {i18next.t("profile")}
                                </button>
                            </Link>
                        }
                        {props.loggedIn &&
                            <Link to={"devices"}>
                                <button
                                    style={{ "textTransform": "capitalize" }}
                                    className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full">
                                    {i18next.t("devices")}
                                </button>
                            </Link>
                        }
                        {!props.loggedIn &&
                            <Link to={"login"}>
                                <button
                                    style={{ "textTransform": "capitalize" }}
                                    className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full">
                                    {i18next.t("login")}
                                </button>
                            </Link>
                        }
                        {props.admin &&
                            <React.Fragment>
                                <NavbarLinkButton toLink={"usermanagement"} >
                                    {i18next.t("Users")}
                                </NavbarLinkButton>
                                <NavbarLinkButton toLink={"registration"} >
                                    {i18next.t("register")}
                                </NavbarLinkButton>
                            </React.Fragment>
                        }
                        {props.admin && 
                            <Link to={"company"}>
                            <button
                                style={{ "textTransform": "capitalize" }}
                                className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full">
                                {i18next.t("company")}
                            </button>
                        </Link>
                    }
                        {props.loggedIn &&
                            <button
                                style={{ "textTransform": "capitalize" }}
                                className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full" onClick={() => {
                                    dispatch(tokenClear());

                                    navigate('/');
                                }}>
                                {i18next.t("logout")}
                            </button>
                        }


                    </div>
                </div>
            </div>
        </div>
    )
}

function mapStateToProps(state) {
    return {
        loggedIn: state.token !== undefined,
        admin: state.admin || state.companyAdmin,
    };
}

export default connect(mapStateToProps)(Navbar);
