
import axios from "axios";

import i18next from "i18next";
import CommandButton from "../live/commandButton"
import { ModalPage, styles } from "./twoFactorModal";
import CodeInput, { InputId } from "./codeInput";

const TwoFactorValidationModal = ({
  closeModal,
  loginSuccess,
  requestToken,
}) => {
  function verifyWithCode() {
    console.log(InputId);
    const code = document.getElementById(InputId).value;

    axios.post(`/api/authentication/validateOtp`, { token: code }, { headers: { 'twoFactorAuthToken': `${requestToken}`, 'Content-Type': 'application/json' } }).then((res) => {
      loginSuccess(res.data);
      closeModal();
    }).catch(err => console.log(err));
  }

  return (<ModalPage>
    <div className="" style={{marginTop: 75}}>
      <h4 className={styles.heading4} style={{padding: 5}}>{i18next.t("Verify authentication code")}</h4>
      <div className="py-3 flex justify-center">
        <CodeInput verifyWithCode={verifyWithCode}/>
      </div>
    </div>
    <div className="flex justify-between my-2">
      <div className="w-1/4" >
        <CommandButton text={i18next.t("Cancel")} onClick={closeModal} />
      </div>
      <div className="w-1/4 left-0" >
        <CommandButton text={i18next.t("Verify")} onClick={() => verifyWithCode()} />
      </div>
    </div>
  </ModalPage>);
}

export default TwoFactorValidationModal;