
const InputId = 'verification-code-input';
export default function CodeInput({verifyWithCode, codeElementId}) {
  return (<input
    id={InputId}
    type="text"
    maxlength="6"
    onInput={(e) => e.target.value = e.target.value.replace(/[^0-9]/g, '')}
    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
    placeholder="Authentication Code"
    autoComplete="off"
    onKeyDown={(e) => {
      if (e.key === 'Enter') {
        verifyWithCode();
      }
    }}
  />);
}

export { InputId };