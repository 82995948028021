import i18next from "i18next"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { setStatusMessage } from "../redux/store"
import styles from "./modalStyle.module.css"
import CommandButton from "./live/commandButton"
import axios from "axios"

const ConfModal = ({ modalClose, currentConfig }) => {
  const [value, setValue] = useState(
    JSON.stringify(currentConfig.configuration.configuration)
  )
  const dispatch = useDispatch()
  return (
    <>
      <div
        className="fixed w-1/2 max-h-1/2 min-w-min z-50"
        style={{ left: "25vw", top: "10vw"}}
      >
        <div className=" bg-gray-900">
          <div className=" text-cyan-500 text-center bg-gray-800 text-2xl font-bold p-3">
            <h1>{i18next.t("Edit configuration")}</h1>
          </div>
          <div className="p-5">
            <textarea
              id="deviceConfig"
              rows="12"
              className="block p-2 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              placeholder="{}"
              value={value}
              onChange={(event) => setValue(event.target.value)}
            />
          </div>
          <div className="flex p-5 justify-between text-cyan-400">
            <div className="w-1/5 px-5 min-w-min">
              <CommandButton text={i18next.t("Cancel")} onClick={modalClose} />
            </div>
            <div className="w-1/5 px-5 min-w-min">
              <CommandButton
                text={i18next.t("Save")}
                onClick={() => {
                  const newConf = document.getElementById("deviceConfig").value

                  const jsonObj = JSON.parse(newConf)
                  // Parsing will thow in case of invalid json
                  axios
                    .post(`/api/ui/device/${currentConfig.id}/conf`, {
                      configuration: jsonObj,
                    })
                    .then(() => {
                      dispatch(
                        setStatusMessage({
                          msg: i18next.t("Configuration saved."),
                        })
                      )
                      modalClose()
                    })
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ConfModal
