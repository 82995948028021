import Navbar from "../components/navbar"
import { Outlet } from "react-router-dom"
import Navbar2 from "../components/Navbar2"

export default function Root() {
  return (
    <>
{/*     <Navbar /> */}
    <Navbar2 />
      <div id="detail">
        <Outlet />
      </div>
    </>
  )
}
