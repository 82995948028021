import i18next from "i18next"
import React, { useState } from "react"
import { useDispatch } from "react-redux"
import { setStatusMessage } from "../redux/store"
import CommandButton from "./live/commandButton"
import axios from "axios"

const ChangeTextModal = ({
  title = "",
  defaultValue = "",
  success = null,
  close = null,
}) => {
  const [value, setValue] = useState(defaultValue)
  return (
    <>
      <div className="fixed w-1/4 min-w-min bg-gray-900" style={{left: "37.5vw", top: "15vw", zIndex: 10}}>
        <div className=" text-cyan-500 text-center bg-gray-800 text-2xl font-bold p-3">
          <h1>{title}</h1>
        </div>
        <div className="p-5 mx-5 flex">
          <input
            className="p-5 w-full mx-auto text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
          ></input>
        </div>
        <div className="flex p-5 justify-between text-cyan-400">
          <div className="w-2/5 min-w-min px-5">
            <CommandButton
              text={i18next.t("Cancel")}
              onClick={() => {
                setValue("")
                if (close) close()
              }}
            />
          </div>
          <div className="w-2/5 min-w-min px-5">
            <CommandButton
              text={i18next.t("Save")}
              onClick={() => {
                let tmp = value
                setValue("")
                if (success) success(tmp)
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default ChangeTextModal
