import React from "react"

const Checkbox = (props) => {
  return (
    <div className="w-36 mr-1">
      <input onChange={(e) => props.onChange(e, props.id)} type="checkbox" id={props.id} className="peer hidden" />
      <label
        htmlFor={props.id}
        className="block cursor-pointer select-none rounded-full text-center peer-checked:bg-cyan-600 peer-checked:font-bold peer-checked:text-white border-2 border-gray-600 "
      >
        {props.text}
      </label>
    </div>
  )
}

export default Checkbox
