import React from "react"

export default function RadioButton(props) {
  return (
    <div className="w-36 mx-2">
      <input
        type="radio"
        name={props.group}
        id={props.name}
        className="peer hidden"
        value={props.name}
        onChange={props.onChange}
      />
      <label
        htmlFor={props.name}
        className="capitalize block cursor-pointer select-none rounded-full text-center peer-checked:bg-cyan-600 peer-checked:font-bold peer-checked:text-white border-2 border-gray-600"
      >
        {props.labelText}
      </label>
    </div>
  )
}

