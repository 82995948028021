export default function CommandButton({ text = "dummy", disabled = false, onClick = null }) {
    return (
            <button
                type="button"
                className="bg-slate-800 hover:bg-slate-700 font-medium p-3 mb-2 w-full"
                onClick={onClick}
            >
                {text}
            </button>
    )
}
