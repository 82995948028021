import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect, useDispatch } from 'react-redux';
import { HttpStatusCode } from 'axios';
import RoutePage from '../components/routePage';
import DropDownSelector from '../components/DropDownSelector';
import { setStatusMessage } from '../redux/store';
import { useTranslation } from 'react-i18next';

function SuccessHeader(props) {
    const { text } = props;
    return (<div>
        <div className="text-left">
            <h1 className="text-white text-3xl p-5">{text}</h1>
        </div>
    </div>);
};

function NewCompanyForm(props) {
    const { submit } = props;
    return (
        <form onSubmit={submit} method="post">
            <section className="p-3">
                <label className="p-5 text-white">Name of the company</label>
                <input
                    id='companyName'

                    required

                />
            </section>

            <button className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full" type="submit">Create</button>
        </form>);
};

const roleElementId = 'roleSelection';
const normalUser = 'Normal user';
const companyAdmin = 'Company admin';
const adminStr = 'Admin';
export {normalUser, companyAdmin, adminStr};

function NewUserForm(props) {
    const { submit, inviteUser, companyOptions, admin } = props;
    const { t, i18n } = useTranslation();

    let roleOptions = [{ name: normalUser }, { name: companyAdmin }];
    if (admin) {
        roleOptions.push({ name: adminStr });
    }
    return (<form onSubmit={submit} method="post">
        <section className="p-3">
            <label className="p-5 text-white">Email</label>
            <input className="w-[20rem]"
                id='email'
                required
                type='email'
            />
        </section>
        <section className="p-3">
            <label className="p-5 text-white">Password</label>
            <input className="w-[18rem]"
                id='password'
                type='password'
                minLength={8}
                required

            />
        </section>
        <section className="p-3">
            <label className="p-5 text-white">Company</label>
            <DropDownSelector options={companyOptions} selectElementId='company' />

        </section>
        <section className="p-3">
            <label className="p-5 text-white">Role</label>
            <DropDownSelector options={roleOptions} selectElementId={roleElementId} />

        </section>
        <button className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full" type="submit">Register</button>
        <button className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full" onClick={inviteUser}>{t("Invite email")}</button>
    </form>);
};

function Registration(props) {
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [userRegistered, setRegistered] = useState(false);
    const [companyRegistered, setCompanyRegistered] = useState(false);
    const [userInvited, setUserInvited] = useState(false);
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    useEffect(() => {
        async function fetchData() {
            setLoading(true);
            try {
                const response = await axios.get('/api/company');
                setData(response.data);
            } catch (error) {

            }
            setLoading(false);
        }
        if (props.loggedIn && data === null && !loading) {

            fetchData();
        }
    }, [props.loggedIn, data, loading]);

    const handleInvite = (e) => {
        e.preventDefault();
        const email = document.getElementById('email').value;
        const company = document.getElementById('company').value;
        const role = document.getElementById(roleElementId).value;
        let apiPath = '/api/user/invite';
        axios.post(apiPath, {
            data: {
                email, company, role
            }
        }).then((response) => {
            if (response.status === HttpStatusCode.Ok) {
                setUserInvited(true);
            }
        }).catch((e) => {
            console.error(e);
            dispatch(setStatusMessage({ msg: t("Error"), error: true }))

        });
    }    

    const handleSubmit = (e) => {
        e.preventDefault();
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;
        const company = document.getElementById('company').value;
        const role = document.getElementById(roleElementId).value;
        let apiPath = '/api/user';
        if (role === adminStr) {
            apiPath = '/api/adminUser';
        } else if (role === companyAdmin) {
            apiPath = '/api/companyUser';
        }
        if (!email || !password || !company) return;
        axios.post(apiPath, {
            data: {
                email, password, company, role
            }
        }).then((response) => {
            if (response.status === HttpStatusCode.Ok) {
                setRegistered(true);
            }
        });
    }
    const handleCompanySubmit = e => {
        e.preventDefault();
        const company = document.getElementById('companyName').value;
        if (!company) return;
        axios.post('/api/company', {
            data: {
                name: company
            }
        }).then((response) => {
            if (response.status === HttpStatusCode.Ok) {
                setCompanyRegistered(true);
            }
        });
    }
    if (userInvited) {
        return <SuccessHeader text={'Invitation email sent!'} />
    }

    if (userRegistered) {
        return <SuccessHeader text={'User registered!'} />
    }
    if (companyRegistered) {
        return <SuccessHeader text={'Company created!'} />;
    }
//    <InviteUser submit={handleInvite} companyOptions={data} admin={props.admin} />
    if (data && data.length > 0) {

        return (<RoutePage header='Register user'>
            <NewUserForm inviteUser={handleInvite} submit={handleSubmit} companyOptions={data} admin={props.admin} />
            {props.admin && <RoutePage header='Create new company'>
                <NewCompanyForm submit={handleCompanySubmit} />
                </RoutePage>
            }
        </RoutePage>)

    }
    return null;

};

function mapStateToProps(state) {
    return {
        loggedIn: state.token !== undefined,
        admin: state.admin,
        companyAdmin: state.companyAdmin,
    };
}

export default connect(mapStateToProps)(Registration);