import React, { useEffect, useState } from 'react'
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { tokenSet, setAdmin, setCompanyAdmin, setTwoFactorAuth, setStatusMessage } from '../redux/store';
import { useDispatch, connect } from 'react-redux';
import i18next from 'i18next';
import { Blocks } from 'react-loader-spinner'

function LoginReset(props) {
    const [validCode, setValidCode] = useState(false);
    const [fetchingCode, setFetchingCode] = useState(true);
    const isLoggedIn = props.loggedIn;
    const dispatch = useDispatch();
    const params = useParams();
    const code = params.code;

    useEffect(() => {
        axios.post('/api/loginResetValidate', { code })
            .then((res) => {
                setFetchingCode(false);
                setValidCode(true);
            })
            .catch((e) => {
                setFetchingCode(false);
            });

    }, []);

    const handleSubmit = e => {
        e.preventDefault();
        const password = document.getElementById('current-password').value;

        axios.post('/api/loginReset', { code, password })
            .then((res) => {
                dispatch(setStatusMessage({ msg: i18next.t("Password reset success") }));
            })
            .catch((e) => {
                dispatch(setStatusMessage({ msg: i18next.t("Error"), error: true }));
            });
    }
    if (fetchingCode) {
        return (
            <div className="flex w-screen p-5 text-white">
                <div className="w-4/5 flex justify-center">

                    <Blocks
                        visible={true}
                        height="80"
                        width="80"
                        ariaLabel="blocks-loading"
                        wrapperStyle={{}}
                        wrapperClass="blocks-wrapper"
                    />
                </div>
            </div>
        );
    }
    else if (!validCode) {

    }
    else if (isLoggedIn) {
        return (
            <div className="flex w-screen justify-center p-5">
                <div className="text-white text-center w-2/5">
                    <h1 className="text-3xl p-3">{i18next.t("Logged in")} </h1>

                </div>
            </div>
        )
    } else {
        return (
            <div className="flex w-screen p-5 text-white">
                <div className="w-4/5 flex justify-center">
                    <div className="">
                        <form onSubmit={handleSubmit} method="post">
                            <section>
                                <p className="text-xl" >{i18next.t("New Password")}</p>
                                <input className='text-black' id="current-password" name="password" type="password" autoComplete="current-password" required />
                            </section>
                            <button className=" hover:bg-slate-700 text-white font-bold py-2 px-4 h-full" type="submit">{i18next.t("Set new password")}</button>
                        </form>
                    </div>
                </div>
            </div>
        );

    }
}

function mapStateToProps(state) {
    return {
        loggedIn: state.token !== undefined,
    };
}

export default connect(mapStateToProps)(LoginReset);