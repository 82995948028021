import React, { useState, useEffect } from "react"
import SensorMap from "../components/sensorMap"
import SensorGraphs from "../components/SensorGraphs"
import { initMeasTypes, measTypes } from "../services/serial"
import { useDispatch, connect } from "react-redux"
import { setStatusMessage } from "../redux/store"
import { epochUTC, timeToUI } from "../services/util"
import axios from "axios"
import i18next from "i18next"
import SensorTable from "../components/sensorTable"
import { MeasTable } from "../components/MeasTable"
import RadioButton from "../components/radioButton"

//{tableData.length > 0 ? <MeasTable data={tableData} /> : <></>}

export const avgTypes = {
    NONE: 0,
    HOUR: 1,
    DAY: 2, 
};


export function Dashboard({ admin, loggedIn }) {
  const [tableData, setTableData] = useState([])
  const [sensors, setSensors] = useState([])
  const [currentSensor, setCurrentSensor] = useState("")
  const [pmTypesInGraph, setPMTypesInGraph] = useState([])
  const [envTypesInGraph, setEnvTypesInGraph] = useState([]);
  const [typesInGraph, setTypesInGraph] = useState([]);
  const [query, setQuery] = useState(null)
  const [tableColumns, setTableColumns] = useState([])
  const apiBase = `/api/ui`

  const [fetchingData, setFetchingData] = useState(false)

  const dispatch = useDispatch();

  useEffect(() => {
    initMeasTypes().then(() => {
      const pmTypes = ["s1", "s2", "s3"];
      const envTypes = ["temperature", "humidity", "pressure"];
      const pt = measTypes.filter((e) => pmTypes.find(v => v === e.name));
      setPMTypesInGraph(pt);
      const env = measTypes.filter((e) => envTypes.find(v => v === e.name));
      setEnvTypesInGraph(env);

      const types = measTypes.filter((e) => e.label && e.graph);
      setTypesInGraph(types);
      let cols = []
      for (let obj of measTypes) {
        if (obj.label && !obj.hide) {
          cols.push({
            Header: obj.label,
            accessor: obj.name,
          })
        }
      }
      setTableColumns(cols)

      if(loggedIn) {
      axios
        .get(`${apiBase}/device`)
        .then((res) => {
          if (!res || !res.data.length || !Array.isArray(res.data)) return
          setSensors([...res.data])
          if(res.data.length > 0) {
            setQuery({
                deviceMac: res.data[0].name,
                label: res.data[0].label,
            });
          }
        })
        .catch((err) => console.error(err.toString()))
    }
    })
  }, []);

  useEffect(() => {
    if(query) {
        fetchMeas(query);
    }

  }, [query]);


    async function fetchLatest({ deviceMac }) {
        let stopEpoch = epochUTC()
        let startEpoch = stopEpoch - 3600 * 5; // need better handling....
        let fields = typesInGraph.map(vt => vt.name);
        const fieldsTxt = fields.join(",");
        const url = `${apiBase}/device/${deviceMac}/data?start=${startEpoch}&stop=${stopEpoch}&onlyGraph=true&fields=${fieldsTxt}`;
        try {
            let data = await axios.get(url);
            if (data.data && data.data.length > 0) {
                return data.data[data.data.length - 1];
            }
            return null;

        }
        catch (e) {
            console.error(e);
            return null;
        }
    }


  function fetchMeas({ deviceMac, avg = null, }) {
    if(fetchingData)
        return;

    setFetchingData(true);

    let stopEpoch = epochUTC()
    let startEpoch = stopEpoch - 60 * 60 * 24 * 7 // last 30 days
        
    let avgHours = '';
    if(avg === avgTypes.HOUR)
        avgHours = '&avg=1';
    else if(avg === avgTypes.DAY)
        avgHours = '&avg=24';
    
    if(avgHours.length > 0) {
      // fetch more data when averaging
      startEpoch = 1;
    }

    let fields = typesInGraph.map(vt => vt.name);
    const fieldsTxt = fields.join(",");

    const url = `${apiBase}/device/${deviceMac}/data?start=${startEpoch}&stop=${stopEpoch}&onlyGraph=true&fields=${fieldsTxt}${avgHours}`
    axios
      .get(url)
      .then((res) => {

        // if there is invalid query, just set the table data []
        try {
            for (let i in res.data) {
              res.data[i].time = timeToUI(res.data[i].time);
            }
        }
        catch(e) {
            setFetchingData(false);
            setTableData([]);
            return;
        }
        // data comes in last first
        res.data.reverse();
        // replace id with label
        if(query && query.label) {
          for(let dev of res.data) {
            dev["deviceId"] = query.label;
          }
        }
        setTableData(res.data);
        setFetchingData(false)
      })
      .catch((err) => {
        setFetchingData(false);
        console.error(err)
        dispatch(
          setStatusMessage({
            error: true,
            msg: i18next.t("Fetching device data failed"),
          })
        )
      })
  }

  function onSensorSelect(sensor) {
    if(fetchingData)
        return;

    if (sensor.label.length > 0) {
      setCurrentSensor(sensor.label)
    } else {
      setCurrentSensor(sensor.name)
    }
    setQuery({...query, deviceMac: sensor.name, label: sensor.label });
  }

  function avgChange(e) {
    const value = e.target.value;
    switch(value) {
        case "avgday":
            setQuery({...query, avg: avgTypes.DAY});
            break;
        case "avghour":
            setQuery({...query, avg: avgTypes.HOUR});
            break;
        default:
            setQuery({...query, avg: avgTypes.NONE});
            break;
    }
  }

    async function onHover(event, sensor) {
        if (sensor.latest) {
            return;
        }
        let last = await fetchLatest({ deviceMac: sensor.name });
        if(!last)
            return;

        let tmpSensors = [...sensors];
        for (let s of tmpSensors) {
            if (s.name === sensor.name) {
                s.latest = last;
            }
        }
        setSensors(tmpSensors);
    }

  return (
    <>
      <div className="">
        <div className="grid grid-cols-12 border-b-4 border-b-black">
          <div className="col-span-4">
            <SensorMap
              sensors={sensors}
              onHover={onHover}
              onSelect={onSensorSelect}
              mapWidth={900}
              mapHeight={600}
            />
          </div>
          <div
            className="col-span-8 border-x-4  border-x-black"
            style={{ backgroundColor: "#24282B" }}
          >
            <div className="flex p-3 text-white font-bold">
              <RadioButton
                onChange={avgChange}
                group={"tableButton"}
                name="avghour"
                labelText={"1hr average"}
              />
              <RadioButton
                onChange={avgChange}
                group={"tableButton"}
                name="avgday"
                labelText={"Daily average"}
              />
              <RadioButton
                onChange={avgChange}
                group={"tableButton"}
                name="avgnone"
                labelText={"No averaging"}
              />
              <div className="absolute right-12 flex">
                <h1 className="text-white text-lg font-bold">
                  Current sensor:
                </h1>
                <h1 className="text-cyan-500 capitalize text-lg mx-2">
                  {currentSensor}
                </h1>
              </div>
            </div>
              <MeasTable data={tableData} onlyGraph={true} fetching={fetchingData} />
          </div>
        </div>
        <div className="py-5" style={{ backgroundColor: "#24282B" }}>
        <SensorGraphs query={query} valueTypes={pmTypesInGraph} />
        </div>
        <div className="py-5" style={{ backgroundColor: "#24282B" }}>
        <SensorGraphs query={query} valueTypes={envTypesInGraph} buttonPrefix={"graph2"} />
        </div>
      </div>
    </>
  )
}
function mapStateToProps(state) {
    return {
        admin: state.admin,
        loggedIn: state.token !== undefined,
    };
};

export default connect(mapStateToProps)(Dashboard);