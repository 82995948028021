import React, { useState, useEffect } from 'react';
import axios from 'axios';
import i18next from "i18next"
import { setStatusMessage } from '../redux/store';
import { useDispatch, connect } from 'react-redux';
import RoutePage from '../components/routePage';
import DropDownSelector from '../components/DropDownSelector';
import CommandButton from "../components/live/commandButton"
import StyledTable, {
    TableHeaderCell, TableHeaderElement,
    TableBodyElement, TableBodyCell, TableBodyRow
} from '../components/StyledTable';
import ConfModal from '../components/deviceConfModal';
import { MeasTable } from '../components/MeasTable';
import { initMeasTypes, measTypes } from '../services/serial';
import { epochUTC, timeToUI } from '../services/util';
import ChangeTextModal from '../components/ChangeTextModal';
import SensorGraphs from '../components/SensorGraphs';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faSpinner } from "@fortawesome/free-solid-svg-icons";


function Render({ fetching, devices, admin, companyAdmin, companies, onCompanyChange, deviceOption, setShowModal, showModal, nameModal, tableData, commands, graph }) {

    return (

        <RoutePage header={i18next.t('Manage devices')}>
            <StyledTable>
                <TableHeaderElement>
                    <TableHeaderCell>
                        {i18next.t("Name")}
                    </TableHeaderCell>
                    <TableHeaderCell>
                        {i18next.t("Token")}
                    </TableHeaderCell>
                    <TableHeaderCell>
                        {i18next.t("Company")}
                    </TableHeaderCell>
                    <TableHeaderCell>
                        Lat / Lon
                    </TableHeaderCell>
                    <TableHeaderCell>
                        {i18next.t("Last connection")}
                    </TableHeaderCell>
                    {(admin || companyAdmin) &&
                        <TableHeaderCell>
                            <div className="text-center" >
                                {i18next.t("Control")}
                            </div>
                        </TableHeaderCell>
                    }
                </TableHeaderElement>
                <TableBodyElement>
                    {fetching && <FontAwesomeIcon icon={faSpinner} spin inverse style={{ position: "absolute", minHeight: 50, minWidth: 50, left: "50vw", color: "white", marginTop: "5%" }} />}
                    {devices.map((device) => {
                        let currentCompany = '';
                        if (device.owners.length > 0) {
                            currentCompany = device.owners[0].company.name;
                        }
                        let loc = i18next.t("Unknown");
                        if (device.lat && device.lon) {
                            loc = `${device.lat.toFixed(2)} ${device.lon.toFixed(2)}`
                        }
                        const dateOfLastConnection = device.lastPost ? new Date(device.lastPost) : null;
                        return (

                            <TableBodyRow key={device.name}>

                                <TableBodyCell key={device.name}>{device.label ? device.label : device.name}</TableBodyCell>
                                <TableBodyCell key={'2'} cellKey={device.token}>
                                    {device.token}
                                </TableBodyCell >
                                {(admin) &&
                                    <TableBodyCell key={'3'}>
                                        <DropDownSelector options={[{ name: '' }, ...companies]} selectElementId={'companies' + device.id} defaultValue={currentCompany}
                                            onSelect={(e) => onCompanyChange(device.id, e)} className='text-wite bg-slate-800 w-[10rem]' />
                                    </TableBodyCell>
                                }

                                {!(admin) && <TableBodyCell key={'5'}>
                                        {currentCompany}
                                </TableBodyCell>

                                }
                                <TableBodyCell key={'4'}>
                                    {loc}
                                </TableBodyCell>
                                <TableBodyCell key={'lastConnection'} >
                                    {dateOfLastConnection && <div>{dateOfLastConnection.toLocaleDateString(undefined, { year: 'numeric', month: 'numeric', day: 'numeric', hour: 'numeric', minute: 'numeric' })}</div>}
                                </TableBodyCell>

                                {(admin || companyAdmin) &&
                                    <TableBodyCell key={'6'}>
                                        <select className="text-white bg-slate-800" onChange={(e) => deviceOption(e, device)}>
                                            <option>{i18next.t("")}</option>
                                            {commands.map((row) =>

                                                <option key={row.name}>{i18next.t(row.name)}</option>
                                            )}

                                        </select>
                                    </TableBodyCell>
                                }

                            </TableBodyRow>
                        )
                    })}
                </TableBodyElement>
            </StyledTable>

            {showModal && <ConfModal currentConfig={showModal} modalClose={() => setShowModal(null)} />}
            {nameModal.show && <ChangeTextModal title={nameModal.title} close={nameModal.close} success={nameModal.success} />}
            {tableData.length > 0 ? <MeasTable data={tableData} onlyGraph={false} /> : <></>}
            {admin && graph !== null &&
                <div className="py-5" style={{ backgroundColor: "#24282B" }}>
                    <SensorGraphs query={graph.query} valueTypes={graph.types} />
                </div>
            }

        </RoutePage>);
}

function DeviceList(props) {
    const { admin, companyAdmin, userToken } = props;
    const [devices, setDevices] = useState([]);
    const [companies, setCompanies] = useState([]);
    const [showModal, setShowModal] = useState(null);
    const [tableData, setTableData] = useState([]);
    const [nameModal, setNameModal] = useState({});
    const [fetching, setFetching] = useState(false);
    const [graph, setGraph] = useState(null);
    const dispatch = useDispatch();
    const apiBase = `/api/ui`;
    const commands = [
        { name: "Reset token", fn: onTokenReset },
        { name: "Edit config", fn: fetchConfig },
        { name: "Data", fn: fetchMeas },
        { name: "Set name", fn: makeNameModal },
        { name: "Set location", fn: makeLocationModal },
        { name: "Reset location", fn: resetLocation },
        //{ name: "Delete data", fn: null },

    ];

    function makeLocationModal(event, device) {
        setNameModal({
            title: i18next.t("Set location as '0.00 0.00'"),
            show: true,
            success: function (v) {
                setNameModal({});
                if (!v || !v.length) {
                    dispatch(setStatusMessage({ error: true, msg: i18next.t("Invalid input") }));
                    return;
                }
                let vals = v.split(' ');
                if (!vals || vals.length !== 2) {
                    dispatch(setStatusMessage({ error: true, msg: i18next.t("Invalid input") }));
                    return;

                }
                let lat = parseFloat(vals[0]);
                let lon = parseFloat(vals[1]);
                if (!lat || !lon) {
                    dispatch(setStatusMessage({ error: true, msg: i18next.t("Invalid input") }));
                    return;
                }


                axios.post(`${apiBase}/device/${device.id}/location`, { lat, lon })
                    .then(() => {
                        dispatch(setStatusMessage({ msg: i18next.t("Changed location") }))
                        fetchDevices();

                    })
                    .catch((e) => {
                        console.log(e);
                        dispatch(setStatusMessage({ error: true, msg: i18next.t("Location set failed") }));
                    });

            },
            close: function () {
                setNameModal({});
            }
        })
    }

    function makeNameModal(event, device) {
        setNameModal({
            title: i18next.t("Change name"),
            show: true,
            success: function (v) {
                setNameModal({});
                axios.post(`${apiBase}/device/${device.id}/label/${v}`)
                    .then(() => {
                        dispatch(setStatusMessage({ msg: i18next.t("Changed name") }))
                        fetchDevices();

                    })
                    .catch((e) => {
                        console.log(e);
                        dispatch(setStatusMessage({ error: true, msg: i18next.t("Name change failed") }));
                    });

            },
            close: function () {
                setNameModal({});
            }
        })
    }

    function fetchDevices() {

        setFetching(true);
        axios.get(`${apiBase}/device`)
            .then((res) => {
                if (!res || !res.data.length || !Array.isArray(res.data)) {
                    setFetching(false);
                    return;
                }
                setDevices(res.data);
                setFetching(false);
            })
            .catch(err => { 
                console.error(err.toString());
                setFetching(false);
            });
    }
    function fetchData() {

        axios.get(`/api/company`).then((res) => {

            setCompanies(res.data);

        }).catch(err => console.error(err.toString())).finally(() => {
            if (devices.length < 1) {

                fetchDevices();
            }

        });
    }

    function fetchMeasCall(device) {
        const stop = epochUTC();
        const start = 1;

        const url = `${apiBase}/device/${device.name}/data?start=${start}&stop=${stop}`;
        axios.get(url).then((res) => {
            for (let i in res.data) {
                res.data[i].time = timeToUI(res.data[i].time);
            }

            res.data.reverse();

            // replace id with label
            console.log(res.data);
            if(device.label) {
                for(let dev of res.data) {
                  dev["deviceId"] = device.label;
                }
            }
  

            setTableData(res.data);
            dispatch(setStatusMessage({ msg: i18next.t("Device data") }));
        }).catch(err => {
            console.error(err);
            dispatch(setStatusMessage({ error: true, msg: i18next.t("Failed getting data") }));
        });
    }
    function deviceOption(event, device) {
        if (!event.target.value)
            return;

        const fn = commands.filter(n => n.name === event.target.value)[0].fn;
        if (fn) {
            fn(event, device);
        }

        event.target.value = "";
    }

    function fetchMeas(event, device) {
        initMeasTypes().then(() => {
            fetchMeasCall(device);
            let debugTypes = ["rssi", "resets"];
            let types = measTypes.filter(e => debugTypes.find(v => v === e.name));
            setGraph({
                query: {
                    deviceMac: device.name,
                    start: epochUTC() - (60 * 60 * 24 * 30),
                    stop: epochUTC()
                },
                types
            });
        });
    }

    function resetLocation(event, device) {
        axios.post(`${apiBase}/device/${device.id}/resetLocation`)
            .then(() => {
                fetchDevices();

            })
            .catch((e) => {
                console.log(e);
                dispatch(setStatusMessage({ error: true, msg: i18next.t("Location reset failed") }));
            });

    }


    function fetchConfig(event, device) {
        axios.get(`${apiBase}/device/${device.id}/configuration`).then((res) => {

            setShowModal({ id: device.id, configuration: res.data });
        }).catch(err => console.error(err.toString()));
    }
    useEffect(() => {
        if (userToken && companies.length < 1) {
            fetchData();
        }
    });

    function onCompanyChange(deviceId, e) {
        console.log("change", deviceId, e.target.value);
        const company = e.target.value;
        axios.post(`${apiBase}/device/${deviceId}/company`, { data: { name: company } }).then(res => {
            dispatch(setStatusMessage({ msg: i18next.t("Company saved.") }));
        });
    }

    function onTokenReset(event, device) {
        axios.post(`${apiBase}/device/${device.id}/resetToken`).then(() => {
            dispatch(setStatusMessage({ msg: i18next.t("Token reset.") }));
            fetchDevices();
        });
    }

    return Render({ fetching, devices, admin, companyAdmin, companies, onCompanyChange, deviceOption, setShowModal, showModal, nameModal, tableData, commands, graph });
};

function mapStateToProps(state) {
    return {
        admin: state.admin,
        companyAdmin: state.companyAdmin,
        userToken: state.token
    };
}

export default connect(mapStateToProps)(DeviceList);
