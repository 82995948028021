export default function StoreItem() {
    return (
      <div className="grid grid-cols-6 gap-4 text-white p-5 m-5 bg-slate-900">
        <div className="bg-blue-500 h-48 w-48"></div>
        <h1 className="text-3xl">Product name</h1>
        <p className="col-span-3">Product information</p>
        <div className="flex-col m-auto">
          <p className="text-xl p-5">Price: $59.99</p>
          <button
            type="button"
            className="bg-slate-800 hover:bg-slate-700 font-medium text-sm p-3 mb-2 h-16 w-32"
          >
            Add to cart
          </button>
        </div>
      </div>
    )
  }
  