import React from 'react';
import Root from "./routes/root";
import ReactDOM from 'react-dom/client';
import {
    createBrowserRouter,
    RouterProvider,
} from "react-router-dom";
import { Provider } from 'react-redux';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Profile from './routes/profile';
import Home from './components/home';
import ErrorPage from './components/errorPage';
import Support from './routes/support';
import Store from './routes/store';
import SensorData from './routes/sensorData';
import Login from './routes/login';
import LoginReset from './routes/loginReset';
import { store } from './redux/store';
import Registration from './routes/registration';
import Devices from './routes/devices';
import UserManagement from './routes/userManagement';
import StatusPopup from './StatusPopup';
import Dashboard from './routes/dashboard';
import Company from './routes/company'

import i18n from './i18n';
import PrivacyPolicy from './routes/privacy';

function Footer() {
    /*
                <ul class="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                    <li>
                        <a href="#" class="mr-4 hover:underline md:mr-6 ">About</a>
                    </li>
                </ul>

    */
    return (
        <footer className="bg-slate-800 rounded-lg shadow m-4 dark:bg-gray-800 sticky top-[100vh]" style={{ marginLeft: 0, marginRight: 0, marginBottom: 0 }}>
            <div className="w-full mx-auto max-w-screen-xl p-4 md:flex md:items-center md:justify-between">
                <span className="text-sm text-gray-500 sm:text-center dark:text-gray-400">© 2023 <a href="https://senhitu.com/" className="hover:underline">Senhitu</a>. All Rights Reserved.
                </span>
                <ul className="flex flex-wrap items-center mt-3 text-sm font-medium text-gray-500 dark:text-gray-400 sm:mt-0">
                    <li>
                        <a href="/privacy" className="mr-4 hover:underline md:mr-6 ">Privacy Policy</a>
                    </li>
                </ul>
            </div>

        </footer>

    );
}

const router = createBrowserRouter([
    {
        path: "/",
        element: <Root />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <Home />,
            },
            {
                path: "/profile",
                element: <Profile />,
            },
            {
                path: "/store",
                element: <Store />,
            },
            {
                path: "/support",
                element: <Support />,
            },
            {
                path: "/live-data",
                element: <SensorData />,
            },
            {
                path: "/login",
                element: <Login />,
            },
            {
                path: "/loginReset/:code",
                element: <LoginReset />,
            },
            {
                path: "/loginInvite/:code",
                element: <LoginReset />,
            },

            {
                path: "/devices",
                element: <Devices />,
            },
            {
                path: "/registration",
                element: <Registration />,
            },
            {
                path: "/usermanagement",
                element: <UserManagement />,
            },
            {
                path: "/profile",
                element: <Profile />,
            },
            {
                path: "/dashboard",
                element: <Dashboard />,
            },
            {
                path: "/company",
                element: <Company />,
            },
            {
                path: "/privacy",
                element: <PrivacyPolicy />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <Provider store={store}>
        <div className='min-h-screen'>
            <RouterProvider router={router} />
            <Footer />
        </div>
        <StatusPopup />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
