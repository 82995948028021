import { useEffect, useState } from "react"
import { initMeasTypes, measTypes } from "../services/serial"
import { current } from "@reduxjs/toolkit"
import RadioButton from "./radioButton"
import { faL, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


export function MeasTable({ data = [], onlyGraph = false, fetching = false }) {
  const [localTypes, setLocalTypes] = useState([])
  useEffect(() => {
    if (!measTypes.length) {
      initMeasTypes().then((d) => {
        setFilteredTypes()
      })
    } else if (!localTypes.length) {
      setFilteredTypes()
    }
  }, [false])

  const onOptionChange = (e) => {
    console.log(e.target.value)
  }

  function setFilteredTypes() {
    const filtered = measTypes.filter((v) => {
      if (v.hide) return false
      if (onlyGraph && !v.inTable) return false

      return true
    })
    setLocalTypes(filtered)
  }

  /** @brief form csv data from table and click the generated csv download link
   * labels have dots and spaces. When importing to other programs, import with separator set to only comma
   * Currently data values only contain numeric values and date string
   * @note DOESN'T HANDLE ESCAPING
  */
  function downloadCSV(data) {
    const headers = localTypes.map(header => header.label);
    let rows = headers.join(",");

    // map types to data
    // some values might be null, set them as empty string
    // reverse order 
    for(let i = data.length - 1; i >= 0; i--) {
        let row = data[i];
        let tmp = [];
        for(let tp of localTypes) {
            if(tp.name in row) {
                tmp.push(row[tp.name]);
            }
            else {
                tmp.push("");
            }
        }
        rows += "\n" + tmp.join(",");
    }
    const link = document.createElement("a");
    link.setAttribute("href", "data:text/csv;charset=utf-8," + encodeURI(rows));
    link.setAttribute("download", `HituData_${new Date().toISOString().slice(0, 10)}.csv`);
    link.click();
  }


  if(fetching)
    return <FontAwesomeIcon icon={faSpinner} spin inverse style={{ position: "relative", minHeight: 50, minWidth: 50, left: "50%", color: "white", marginTop: "5%" }} />
  else 
  return (
    <>
      <div>
        
        <div
          className=""
          style={{
            overflowX: "auto",
            maxHeight: 490,
            backgroundColor: "#1F2122",
          }}
        >
          <table className="text-white w-full">
            <thead style={{backgroundColor: "#121212", borderColor: "#25292B"}}>
              <tr>
                {localTypes.map((row, index) => (
                  <th key={row.label} className="p-5 text-cyan-500 border-r-4 sticky top-0" style={{backgroundColor: "#121212", borderColor: "#25292B"}}>
                    {row.label} {row.unit ? `(${row.unit})` : ""}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.map((row, rowIndex) => (
                <tr key={rowIndex}>
                  {localTypes.map((item, index) => (
                    <td className="text-center border-4 p-1" key={index} style={{backgroundColor: rowIndex % 2 === 0 ? "#282D31" : "#2E3337", borderColor: "#25292B"}}>                    
                      {item.valueType === 1 ? (parseFloat(row[item.name]).toFixed(1)) : row[item.name]}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div
          className="flex justify-end"
        >
          <div className="p-2">
            <button className="rounded-full px-4 py-2 text-center text-slate-300 bg-cyan-800 hover:text-white hover:bg-cyan-700"
            onClick={() => downloadCSV(data)}
            >
              DOWNLOAD .CSV
            </button>
          </div>
        </div>
      </div>
    </>
  )
}
