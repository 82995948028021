import React from 'react';
import { useEffect } from 'react';
import { setStatusMessage } from './redux/store';
import { useDispatch, connect } from 'react-redux';

const StatusPopup = ({ message, error }) => {
  const dispatch = useDispatch();
  useEffect(() => {
    if (message && message.length > 0) {

      const timer = setTimeout(() => {
        dispatch(setStatusMessage({msg: ""}));
      }, 2000);
      return () => clearTimeout(timer);
    }
  });
  if (!message || message.length < 1) {
    return null;
  }

  return (
    <div
      className={error ? 'fixed w-screen top-14 z-90 bg-red-500 font-bold text-black p-4 shadow-lg text-center' : 'fixed w-screen top-14 z-90 bg-green-400 font-bold text-black p-4 shadow-lg text-center'}
    >
      {message}
    </div>
  );
};

function mapStateToProps(state) {
  return {
    message: state.statusMessage,
    error: state.statusMessageIsError
  }
}

export default connect(mapStateToProps)(StatusPopup);