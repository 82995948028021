import { React, useState, useEffect } from "react"
import { useTranslation } from 'react-i18next';
import CommandButton from "../components/live/commandButton"
import { connect, useDispatch } from "react-redux"
import { store, setStatusMessage } from '../redux/store'
import {
    pipelineStart,
    pipelineStop,
    pipelineSet,
    pipelineCommandCheck
} from '../services/commandPipeline'

import {
    Serial,
    getSerial,
    csvToJson,
    sendRowToBackend,
    measTypes
} from "../services/serial"
import axios from "axios";
import { MeasTable } from "../components/MeasTable"

export const DeviceAuth = {
    AUTH_NONE: 0,
    AUTH_USER: 1,
    AUTH_ADMIN: 2,
};
export const RegisteringState = {
    DONE: 0,
    REGISTERING: 1,
    NOT_FOUND: 2,
};

const loadOnce = true;
const tableLengthMax = 50;
const errorMessagesMaxLength = 5;
let errorMessages = [];



/** @brief received data values that are shown in table */
let slotDataRows = [
];

const apiPathBase = '/api/ui/device';
function GetDeviceApiBasePath(deviceId) {
    return `${apiPathBase}/${deviceId}`;
}

function apiPathData(deviceId) {
    return `${apiPathBase}/${deviceId}/data`;
}




const urlReg = "url";
const tokenReg = "token";
let storedToken = "";
let tokenFromDevice = "";
let enableDataSendCb = false;

function SensorData({ admin, userToken }) {
    const serial = getSerial();
    let [serialSupported] = useState("serial" in navigator);
    let [deviceAuth, setDeviceAuth] = useState(false);
    let [registerValues, setRegisterValues] = useState([]);
    let [portOpen, setPortOpen] = useState(false);
    let [lastLine, setLastLine] = useState("");
    let [stateText, setStateText] = useState("");
    let [lastMessages, setLastMessages] = useState(errorMessages);
    let [deviceId, setDeviceId] = useState("");
    let [existingToken, setExistingToken] = useState("");
    let [lastUpdated, setLastUpdated] = useState(null);
    let [registeringState, setRegisteringState] = useState(RegisteringState.NOT_FOUND);
    let [enableDataSend, setEnableDataSend] = useState(enableDataSendCb);

    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    function msgError(msg) {
        dispatch(setStatusMessage({ msg: msg, error: true }));
    }
    function msgSuccess(msg) {
        dispatch(setStatusMessage({ msg: msg, error: false }));
    }


    /** 
    * @brief device commands
    * commands with no label are not shown
    * login, set and get are handled separately but success function is defined here for consistensy
    * some commands are not real devices commands but are listed here because they have button
    * success is called from command callback
    */
    const commands = {
        "Fetch": { success: null, click: pipelineRegistersInit, label: t("Fetch"), authDevice: false, authCloud: false },
        "update": { success: updateSuccess, click: cmdUpdate, label: t("Update"), authDevice: true, authCloud: false },
        "save": { success: saveSuccess, click: cmdSave, label: t("Save"), authDevice: true, authCloud: false },
        "logout": { success: logoutSuccess, click: cmdLogout, label: t("Logout"), authDevice: true, authCloud: false },
        "reset": { success: null, click: cmdReset, label: t("Reset"), authDevice: false, authCloud: false },
        "start": { success: null, click: cmdStart, label: t("Start"), authDevice: false, authCloud: false },
        "get": { success: getSuccess, click: null, label: null, authDevice: false, authCloud: false },
        "set": { success: setSuccess, click: null, label: null, authDevice: false, authCloud: false },
        // device supports normal login and admin login, for now use admin login
        "sulogin": { success: loginSuccess, click: null, label: null, authDevice: false, authCloud: false },
        "register_pipeline": { success: null, click: pipelineDeviceRegister, label: t("Set Hitu token"), authDevice: false, authCloud: false },
        //"deploy": { success: null, click: registerDeviceToServer, label: t("Register device"), authDevice: false, authCloud: false },
        //"renew": { success: null, click: renewRegistration, label: t("Renew device"), authDevice: false, authCloud: false },
    }

    /**
     * @brief device registers used with get / set commands
    * name is the register name in device that is not shown to user
    * label is translated in rendering function
    * auth means value is only fetched if device is authenticated. Authentication is checked with register "auth"
    * edit field is used as text input buffer
    * value is fetched value from the device set from getSuccess 
    * gotFn called on succesfull get
    * setFn called when set was successful
    */
    let regVals = [
        // ORDER MATTERS, kind of. Init pipeline is build from these values
        { label: null, name: "auth", gotFn: gotAuth, value: "", auth: false, edit: "", editable: false },

        { label: "ID", name: "deviceId", gotFn: gotDeviceId, value: "", auth: false, edit: "", editable: false },
        { label: "Data upload / print interval", name: "result_interval", value: "", auth: true, edit: "", editable: true },
        { label: "Full data", name: "full_data", value: "", auth: true, edit: "", editable: true },
        { label: "No data processing", name: "meas", value: "", auth: true, edit: "", editable: true },
        { label: "Debug printing / requires reset", name: "debug", value: "", auth: true, edit: "", editable: true },

        { label: "Fan %", name: "fan_target", value: "", auth: false, edit: "", editable: true },
        { label: "Environment interval", name: "bmx_interval", value: "", auth: true, edit: "", editable: true },
        { label: "Environment average count", name: "bmx_avg_count", value: "", auth: true, edit: "", editable: true },
        { label: "Interval 1", name: "sen_interval", value: "", auth: true, edit: "", editable: true },
        { label: "Measurement average count", name: "sen_avg_count", value: "", auth: true, edit: "", editable: true },
        { label: "Interval 2", name: "omron_interval", value: "", auth: true, edit: "", editable: true },
        { label: "Threshold mV", name: "omron_voltage", value: "", auth: true, edit: "", editable: true },

        { label: "β1", name: "sen_var1", value: "", auth: true, edit: "", editable: true },
        { label: "β2", name: "sen_var2", value: "", auth: true, edit: "", editable: true },
        { label: "β3", name: "sen_var3", value: "", auth: true, edit: "", editable: true },
        { label: "β4", name: "sen_var4", value: "", auth: true, edit: "", editable: true },
        { label: "β5", name: "sen_var5", value: "", auth: true, edit: "", editable: true },
        { label: "β6", name: "sen_var6", value: "", auth: true, edit: "", editable: true },
        { label: "β7", name: "sen_var7", value: "", auth: true, edit: "", editable: true },
        { label: "β8", name: "sen_var8", value: "", auth: true, edit: "", editable: true },
        { label: "β9", name: "sen_var9", value: "", auth: true, edit: "", editable: true },

        { label: "Token", name: "token", setFn: setTokenSucess, value: "", auth: true, edit: "", editable: true },
        { label: "URL", name: "url", setFn: setURLSucess, value: "", auth: true, edit: "", editable: true },
        { label: "pin", name: "pin", value: "", auth: true, edit: "", editable: true },
        { label: "User password", name: "passwd", value: "", auth: true, edit: "", editable: true },

        { label: "IMEI", name: "modem_imei", value: "", auth: true, edit: "", editable: false },
        { label: "IMSI", name: "modem_imsi", value: "", auth: true, edit: "", editable: false },
        { label: "ICCID", name: "iccid", value: "", auth: true, edit: "", editable: false },

        { label: "Roaming mode (1 disable) (2 enable) (255 auto)", name: "modem_roam", value: "", auth: true, edit: "", editable: true },
        { label: "Modem scan mode (0 auto) (1 GSM) (3 LTE)", name: "modem_scan", value: "", auth: true, edit: "", editable: true },
        { label: "Cellular modem band GSM and WCDMA", name: "modem_band", value: "", auth: true, edit: "", editable: true },
        { label: "Cellular modem band LTE", name: "modem_band_lte", value: "", auth: true, edit: "", editable: true },

        { label: "Startup warmup", name: "start_warmup", value: "", auth: true, edit: "", editable: false },
        { label: "Startup fan off", name: "start_fan_off", value: "", auth: true, edit: "", editable: false },
        { label: "Startup fan spool", name: "start_fan_spool", value: "", auth: true, edit: "", editable: false },
        { label: "Startup fan on", name: "start_fan_on", value: "", auth: true, edit: "", editable: false },


        { label: "S1", name: "s1", value: "", auth: false, edit: "", editable: false },
        { label: "S2", name: "s2", value: "", auth: false, edit: "", editable: false },
        { label: "S3", name: "s3", value: "", auth: false, edit: "", editable: false },
        { label: "S4", name: "s4", value: "", auth: false, edit: "", editable: false },
        { label: "S5", name: "s5", value: "", auth: false, edit: "", editable: false },

        { label: "Start pressure fan on", name: "pressure_fan_on", value: "", auth: false, edit: "", editable: false },
        { label: "Start pressure fan off", name: "pressure_fan_off", value: "", auth: false, edit: "", editable: false },

    ];


    /** @brief pipeline to fetch register values, called on portOpen */
    function pipelineRegistersInit() {
        let tmp = [];

        for (let index in regVals) {
            let row = regVals[index];
            if (row.auth && !deviceAuth)
                continue;

            tmp.push({
                cmd: "get",
                fn: function () {
                    serial.reg(row.name);
                }
            });
        }
        // resume measurement printing as last command
        tmp.push({
            cmd: "start",
            fn: cmdStart
        });
        pipelineSet(tmp);

    }
    /** @brief set device register pipeline, requires admin right */
    function pipelineDeviceRegister() {
        if (!deviceAuth) {
            msgError(t("Login to device first"));
            return;
        }
        if (!admin) {
            msgError(t("You need to login"));
            return;
        }
        if (!existingToken) {
            // get device MAC, token is fetched on succesfull get command
            serial.reg("deviceId");
            setTimeout(function () {
                if (!existingToken) {
                    msgError(t("Failed to get device token form cloud"));
                    return;
                }

                pipelineDeviceRegisterInit();

            }, 1000);
        }
        else {
            pipelineDeviceRegisterInit();
        }

    }

    /** @brief pipeline to set URL and token for device. Token needs to be get from server first */
    function pipelineDeviceRegisterInit() {
        let tmp = [
            {
                cmd: "set",
                fn: () => { serial.reg(urlReg, window.location.host); }
            },
            {
                cmd: "set",
                fn: () => { serial.reg(tokenReg, existingToken); }
            },
            {
                cmd: "update",
                fn: () => { serial.cmdUpdate(); }
            },
            {
                cmd: "save",
                fn: () => { serial.cmdSave(); }
            },
        ]
        pipelineSet(tmp);

    }

    function validateRegShow(row) {
        if (!row.label)
            return false;

        if (row.auth && !deviceAuth)
            return false;
        return true;

    }
    // check for register input edit field
    function validateRegEditable(row) {
        if (!row.editable)
            return false;

        if (row.auth && !deviceAuth)
            return false;
        return true;
    }

    /** @brief Receive measurement line of text from device */
    function cbRead(text) {
        setLastLine(text);
        let json = csvToJson(text);

        if (!json) {
            return;
        }
        if (slotDataRows.length >= tableLengthMax) {
            slotDataRows.pop();
        }
        const locale = navigator.languages && navigator.languages.length > 0 ? navigator.languages[0] : 'en-US';
        let options = {
            year: "numeric",
            month: "numeric",
            day: "numeric",
            hour: "numeric",
            minute: "numeric",
            hour12: false,
        };
        const date = new Date(json.time);
        json.time = new Intl.DateTimeFormat(locale, options).format(date);


        slotDataRows.unshift(json);

        // TODO: callback function doesn't have access to current react state...
        if (enableDataSendCb) {
            axios.post(apiPathData(json.deviceId), json).catch((e) => {
                // ignore 403 and other possible errors
            });
        }
    }

    /**
     * @brief Handle state change and error messages
     */
    function cbStateChange({ portOpen, state, errorMsg }) {
        console.log("change", portOpen, state, errorMsg)
        if (portOpen !== null && portOpen !== undefined) {
            setPortOpen(portOpen);
            if (portOpen) {
                pipelineStart();
                pipelineRegistersInit();

                setRegisterValues([...regVals]);
            }
            if (!portOpen) {
                pipelineStop();
                msgError(t("USB lost"));
            }
        }
        if (state !== null && state !== undefined) {
            setStateText(state)
        }
        if (errorMsg !== null && errorMsg !== undefined) {
            if (errorMessages.length >= errorMessagesMaxLength) {
                errorMessages.shift();
            }
            errorMessages.push(errorMsg);
            setLastMessages(errorMessages);
        }
    }

    // run once to set callbacks to serial
    useEffect(() => {
        if (serial) {
            serial.register(cbRead, cbCmdResponse.bind(this), cbStateChange)
            return () => {
                serial.close();
            }
        }
    }, [loadOnce])

    function cbCmdResponse(cmd, args, responseStatus, responseRest) {
        if (!responseStatus) {
            msgError(t("Command failed"));
            return;
        }
        pipelineCommandCheck(cmd);

        if (!(cmd in commands)) {
            console.log("cmd response but command not defined:", cmd);
            return;
        }

        if (commands[cmd].success)
            commands[cmd].success(args, responseRest);

    }

    function gotAuth(v) {
        if (parseInt(v) === DeviceAuth.AUTH_ADMIN && !deviceAuth)
            setDeviceAuth(true);
        else if (parseInt(v) === DeviceAuth.AUTH_NONE && deviceAuth)
            setDeviceAuth(false);
    }
    /** @brief got device id, fetch device data from cloud if not fetched */
    function gotDeviceId(value) {
        if (deviceId && value === deviceId)
            return;

        if (userToken) {
            axios.get(GetDeviceApiBasePath(value) + "/getOrCreate").then(response => {
                const { token } = response.data;
                if (token) {
                    setExistingToken(token);
                }
            }).catch((e) => {
                // ignore 403 
            });
        }
        setDeviceId(value);
    }

    function writeRegistrationToDevice(token) {
        if (serial) {
            storedToken = token;
            serial.reg(urlReg, window.location.host);
        }
    }

    /**
     * @brief Register device to database. When successfull, device id is stored to database with token. This same token and url to server is stored to device.
     */
    function registerDeviceToServer() {
        let result = false;
        if (deviceId.length > 0) {
            axios.post(GetDeviceApiBasePath(deviceId) + "/create").then(result => {
                const { token } = result.data;
                writeRegistrationToDevice(token);
            }).catch(err => {
                console.log(err);
            });
        }
        return result;
    }

    /**
     * @brief Update registration data receiver from server to device
     */
    function renewRegistration() {
        writeRegistrationToDevice(existingToken);
    }

    async function newPort() {
        if (portOpen) {
            await serial.close();
        }
        else {
            await serial.getPort();
        }
    }

    function updateSuccess(args, response) {
        msgSuccess(t("Update success"));

    }
    function saveSuccess() {
        msgSuccess(t("Save success"));
    }
    function logoutSuccess() {
        setDeviceAuth(false);
    }

    function regGetSuccess(reg, value) {
        for (let index in regVals) {
            if (regVals[index].name === reg) {
                regVals[index].value = value;
                if (regVals[index].gotFn)
                    regVals[index].gotFn(value);


                setRegisterValues([...regVals]);
            }
        }
    }

    function setTokenSucess(token) {

    }
    function setURLSucess(url) {


    }

    // get reg => get status value    
    // args = [reg]
    // rest = value
    function getSuccess(args, rest) {
        regGetSuccess(args[0], rest);
    }

    function setSuccess(args, value) {
        let reg = args[0];
        console.log("set success", reg, value);
        for (let index in regVals) {
            if (regVals[index].name === reg) {
                regVals[index].value = value;
                if (regVals[index].setFn) {
                    regVals[index].setFn(value);
                }
                setRegisterValues([...regVals]);
                setLastUpdated(new Date());

                break;
            }
        }


    }
    function loginSuccess() {
        console.log("login success");
        serial.reg(tokenReg);
        setDeviceAuth(true);
    }

    function cmdReset() {
        serial.cmdReset();
        setDeviceAuth(false);
    }
    function cmdUpdate() {
        serial.cmdUpdate();
    }
    function cmdSave() {
        serial.cmdSave();
    }
    function cmdLogout() {
        serial.cmdLogout();
    }
    function cmdStart() {
        serial.cmdStart();
    }
    function cmdStop() {
        serial.cmdStop();
    }

    function passwordEnter(e) {
        if (e.key === "Enter") {
            serial.cmdLogin(e.target.value);
            e.target.value = "";
        }
    }

    function regClick(name, e) {
        for (let i in regVals) {
            if (regVals[i].name === name) {
                serial.reg(regVals[i].name);
                break;
            }
        }
    }
    function onEditEnter(name, e) {
        if (e.key !== "Enter")
            return;

        for (let i in regVals) {
            if (regVals[i].name === name) {
                serial.reg(regVals[i].name, e.target.value);
                e.target.value = "";
                break;
            }
        }
    }

    function onEditChange(name, e) {
        for (let i in regVals) {
            if (regVals[i].name === name) {
                regVals[i].edit = e.target.value;
                break;
            }
        }
    }

    function toggleDataSend() {
        setEnableDataSend(!enableDataSend);
        enableDataSendCb = !enableDataSend;
    }

    function PortOpenCommands() {
        let displayCommands = Object.keys(commands).filter((cmd) => {
            if (!commands[cmd].label)
                return false;
            if (commands[cmd].authDevice && !deviceAuth)
                return false;
            if (commands[cmd].authCloud && !admin)
                return false;

            return true;
        });

        return (
            <div>
                {displayCommands.map((cmd) =>
                    <CommandButton key={"ui_" + cmd}
                        text={commands[cmd].label}
                        onClick={commands[cmd].click}
                    />
                )}
                {admin ?
                    <CommandButton key={"ui_send_data"}
                        text={t(enableDataSend ? "Disable serial data send" : "Enable serial data send")}
                        onClick={toggleDataSend}
                    />
                    :
                    <></>

                }
            </div>
        );

    }

    return (
        <>
            <div className="">
                <div>

                    {serialSupported ? (
                        <div className="grid grid-cols-12 gap-4 p-5">
                            <div className="text-white col-span-2 mx-auto">
                                <CommandButton text={stateText} />
                                <CommandButton
                                    text={
                                        portOpen ? t("Close port") : t("New port")
                                    }
                                    onClick={newPort}
                                />
                                {portOpen ? <PortOpenCommands /> : (
                                    <div />
                                )}
                                {portOpen && !deviceAuth &&
                                    <div>
                                        <label>
                                            {t("password")}{" "}
                                            <input key="password_field"
                                                className="text-black"
                                                type="text"
                                                onKeyUp={passwordEnter}
                                            />
                                        </label>
                                    </div>

                                }
                            </div>
                            <div className="col-span-10" style={{ overflowX: "auto", maxHeight: 600 }} >
                                <table className="text-white bg-slate-900 w-full">
                                    <thead>
                                        <tr>
                                            {["Register", "Value", "Edit"].map((v) =>
                                                <th key={v} className="p-5 bg-slate-800">{t(v)}</th>
                                            )}

                                        </tr>
                                    </thead>
                                    <tbody>
                                        {registerValues.filter(v => validateRegShow(v)).map((row, index) =>
                                            <tr key={row.name} style={{ borderBottom: "1px solid" }}>
                                                <td className="p-2 text-left" style={{ cursor: "pointer" }}
                                                    onClick={(e) => { regClick(row.name, e) }}
                                                >
                                                    {t(row.label)}
                                                </td>
                                                <td className="p-2 text-center">{row.value}</td>
                                                <td className="p-2 text-center">
                                                    {validateRegEditable(row) ? (
                                                        <input
                                                            className="text-black"
                                                            key={index}
                                                            onKeyUp={(e) => onEditEnter(row.name, e)}
                                                        />
                                                    ) : (
                                                        <div></div>
                                                    )}
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>

                            </div>
                        </div>
                    ) : (
                        <div>
                            <p className="text-white">
                                {t("not_supported")}
                            </p>
                            <a
                                className="underline text-blue-600 hover:text-blue-800 visited:text-purple-600"
                                href="https://developer.mozilla.org/en-US/docs/Web/API/Web_Serial_API#browser_compatibility"> {t("See supported browsers")}</a>
                        </div>
                    )}

                </div>
                <MeasTable data={slotDataRows} />
                <div className="p-5" style={{ overflowX: "auto", maxHeight: 400 }}>
                    <table className="table-auto text-white bg-slate-900 w-full">
                        <tbody>
                            {lastMessages.map((row, index) =>
                                <tr key={index}><td>{row}</td></tr>
                            )}
                        </tbody>
                    </table>
                </div>

            </div>
        </>
    );
}
// "This web browser does not support web serial."
function mapStateToProps(state) {
    return {
        admin: state.admin,
        userToken: state.token
    };
}

export default connect(mapStateToProps)(SensorData);
