import { createSlice, configureStore } from '@reduxjs/toolkit'
import axios from 'axios';
import StatusCode from 'status-code-enum';
import i18next from "i18next";

const recoverFromLocalStorage = () => {
    const data = localStorage.getItem('token_data');
    return data ? JSON.parse(data) : null;
};

const AuthSlice = createSlice({
    name: 'token',
    initialState: {
        token: undefined,
        admin: false,
        companyAdmin: false,
        statusMessage: "",
        twoFactorEnabled: false,
        conf: null,
    },
    reducers: {
        tokenSet: (state, action) => {

            state.token = action.payload;
            axios.defaults.headers.common = { 'Authorization': `Bearer ${action.payload}`, 'Content-Type': 'application/json' };
        },
        setConf: (state, action) => {
            state.conf = action.payload;
        },
        setAdmin: (state, action) => {
            state.admin = action.payload;
        },
        setCompanyAdmin: (state, action) => {
            state.companyAdmin = action.payload;
        },
        tokenClear: state => {
            state.token = undefined;
            state.admin = false;
            state.companyAdmin = false;
            localStorage.setItem('token_data', JSON.stringify({}));
        },
        setStatusMessage: (state, action) => {
            const { msg, error = false } = action.payload;
            state.statusMessage = msg;
            state.statusMessageIsError = error;
        },
        setTwoFactorAuth: (state, action) => {
            state.twoFactorEnabled = action.payload;
        },
    }
})

export const { tokenSet, tokenClear, setAdmin, setCompanyAdmin, setStatusMessage, setTwoFactorAuth, setConf } = AuthSlice.actions

export const store = configureStore({
    reducer: AuthSlice.reducer
});
// For debugging
//store.subscribe(() => console.log(store.getState()));
const data = recoverFromLocalStorage();
if (data) {
    if (data.token) store.dispatch(tokenSet(data.token));

    if (data.conf)
        store.dispatch(setConf(data.conf));

    if (data.adminRole) store.dispatch(setAdmin(data.adminRole));
    if (data.companyAdminRole) store.dispatch(setCompanyAdmin(data.companyAdminRole));
}

function getErrorStatusMsg(message) {
    return { msg: message, error: true };
}

axios.interceptors.response.use(function (response) {
    if (response.data.message) {
        store.dispatch(setStatusMessage({ msg: response.data.message }));
    }
    return response;
}, function (error) {
    const { response } = error;
    if (error.response.status === StatusCode.ClientErrorUnauthorized) {
        store.dispatch(setStatusMessage(getErrorStatusMsg(i18next.t("Login failed"))));
    } else if (response.status === StatusCode.ClientErrorForbidden) {
        //store.dispatch(setStatusMessage(getErrorStatusMsg(i18next.t("Not logged in"))));
        store.dispatch(tokenClear());
        window.location.href = '/login';
    }
    else if (error.response.status === StatusCode.ClientErrorBadRequest) {
        let field = null;
        if (error.response.data.field && Array.isArray(error.response.data.field) && error.response.data.field.length > 0) {
            field = error.response.data.field[0];
            store.dispatch(setStatusMessage(getErrorStatusMsg(i18next.t("Input field wrong: ") + field)));
        } else if (error.response.data.message) {
            // Should translate based on ID, but just show message for now
            store.dispatch(setStatusMessage(getErrorStatusMsg(error.response.data.message)));
        }
        else {
            store.dispatch(setStatusMessage(getErrorStatusMsg(i18next.t("User input was wrong"))));
        }
    }
    return Promise.reject(error);
});
