import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';

import { useDispatch, connect } from 'react-redux';
import axios from 'axios';
import { setStatusMessage, setTwoFactorAuth } from '../redux/store';
import { CompanyInfo } from '../components/CompanyInfo';
import i18next from 'i18next';
import { Navigate } from 'react-router-dom'
import RoutePage from '../components/routePage';
import StyledTable, { TableBodyCell, TableHeaderCell, TableBodyElement, TableHeaderElement, TableBodyRow } from '../components/StyledTable';
import { MeasTable } from '../components/MeasTable';
import { timeToUI } from '../services/util';

export function Company({ admin, companyAdmin }) {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [selectedCompanyData, setSelectedCompanyData] = useState([]);
    const [config, setConfig] = useState(null);
    const [tableData, setTabledata] = useState([]);
    const { t, i18n } = useTranslation();

    useEffect(() => {

        axios.get("/api/company").then((d) => {
            let dat = d.data;
            for (let i in dat) {
                let total = 0;
                for (let di in dat[i].devices) {
                    let dev = dat[i].devices[di];
                    let diff = 0;
                    if (dev.ends) {
                        //console.log(new Date(), new Date(dev.created), new Date() - new Date(dev.created));
                        diff = new Date(dev.ends) - new Date(dev.created)
                    }
                    else {
                        diff = new Date() - new Date(dev.created);
                    }
                    total += diff / (1000 * 60 * 60 * 24);
                }

                dat[i].total = Math.round(total);
            }

            setCompanies(dat);
        });
        if (config)
            return;

    }, []);

    const commands = [
        { name: "Device usage timeline", fn: showCompany },
        { name: "All data", fn: fetchData },
        //{ name: "Delete data", fn: null },
    ];

    function fetchData(event, company) {
        axios.get(`/api/company/${company.id}/data`).then((d) => {
            if (d.data) {
                for (let i in d.data) {
                    d.data[i].time = timeToUI(d.data[i].time);
                }

                // rename name to label
                d.data.reverse();
                for(let row of d.data) {
                    for(let dev of company.devices) {
                        if(row["deviceId"] == dev.device.name && dev.device.label) {
                            row["deviceId"] = dev.device.label;
                        }
                    }
                }


                setTabledata(d.data);
                msgSuccess(t("Data ready"));
            }
            else {
                msgError(t("Error"));
            }
        })
            .catch((e) => {
                console.log(e);
                msgError(t("Error"));

            });
    }

    function showCompany(event, company) {
        setSelectedCompany(company);
    }

    function companyOption(event, device) {
        if (!event.target.value)
            return;

        const fn = commands.filter(n => n.name === event.target.value)[0].fn;
        if (fn) {
            fn(event, device);
        }

        event.target.value = "";
    }


    function msgError(msg) {
        dispatch(setStatusMessage({ msg: msg, error: true }));
    }
    function msgSuccess(msg) {
        dispatch(setStatusMessage({ msg: msg, error: false }));
    }

    const dispatch = useDispatch();
    return (
        <RoutePage header={i18next.t('Company')}>
            <StyledTable>
                <TableHeaderElement>
                    <TableHeaderCell>
                        {i18next.t("Name")}
                    </TableHeaderCell>
                    <TableHeaderCell>{i18next.t("Users")}</TableHeaderCell>
                    <TableHeaderCell>{i18next.t("Active devices")}</TableHeaderCell>
                    <TableHeaderCell>{i18next.t("Total days of use")}</TableHeaderCell>
                    {(companyAdmin || admin) &&
                        <TableHeaderCell>
                            <div className="text-center" >
                                {i18next.t("Control")}
                            </div>
                        </TableHeaderCell>
                    }
                </TableHeaderElement>
                <TableBodyElement>
                    {companies.map((company) =>

                        <TableBodyRow key={company.name}>

                            <TableBodyCell>{company.name}</TableBodyCell>
                            <TableBodyCell>{company.users.length}</TableBodyCell>
                            <TableBodyCell>{company.devices.filter(d => !d.ends).length}</TableBodyCell>
                            <TableBodyCell>{company.total}</TableBodyCell>
                            {(companyAdmin || admin) &&
                                < TableBodyCell >

                                    <select className="text-white bg-slate-800" onChange={(e) => companyOption(e, company)}>
                                        <option>{i18next.t("")}</option>
                                        {commands.map((row) =>
                                            <option key={row.name}>{i18next.t(row.name)}</option>
                                        )}

                                    </select>
                                </TableBodyCell >
                            }

                        </TableBodyRow>

                    )}
                </TableBodyElement>
            </StyledTable>
            {
                selectedCompany &&
                <div className="p-5" style={{ overflowX: "auto", maxHeight: 400 }}>
                    <table className="table-auto text-white bg-slate-900 w-full">
                        <thead>
                            <tr>
                                <th className="p-5 bg-slate-800">{i18next.t("Hitu")}</th>
                                <th className="p-5 bg-slate-800">{i18next.t("Started")}</th>
                                <th className="p-5 bg-slate-800">{i18next.t("Released")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {selectedCompany.devices.map((row, rowIndex) =>
                                <tr key={rowIndex}>
                                    <td>{row.device.name}</td>
                                    <td>{new Date(row.created).toLocaleDateString()}</td>
                                    <td>{row.ends && new Date(row.ends).toLocaleDateString()}</td>
                                </tr>
                            )}

                        </tbody>
                    </table>
                </div>
            }
            {tableData.length > 0 ? <MeasTable data={tableData} onlyGraph={false} /> : <></>}


        </RoutePage >

    )
}
//{showModal && <ConfModal currentConfig={showModal} modalClose={() => setShowModal(null)} />}


function mapStateToProps(state) {
    return {
        admin: state.admin,
        companyAdmin: state.companyAdmin,
        loggedIn: state.token !== undefined,
        twoFactorEnabled: state.twoFactorEnabled,
    };
};

export default connect(mapStateToProps)(Company);