import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import RoutePage from '../components/routePage';

import CommandButton from "../components/live/commandButton"
import StyledTable, {
    TableHeaderCell, TableHeaderElement,
    TableBodyElement, TableBodyCell, TableBodyRow
} from '../components/StyledTable';
import { adminStr, companyAdmin, normalUser } from './registration';
import DropDownSelector from '../components/DropDownSelector';
import { useDispatch } from 'react-redux';
import { setStatusMessage } from '../redux/store';

const RoleMapping = {
    1: normalUser,
    2: companyAdmin,
    3: adminStr,
}


function UserManagement({ admin, companyAdmin }) {
    const [users, setUsers] = useState([]);
    const [commands, setCommands] = useState([]);
    const { t, i18n } = useTranslation();
    const options = Object.keys(RoleMapping).filter((key) => {
        if (!admin && RoleMapping[key] === adminStr)
            return false;

        return true;
    }).map((key) => {
        return { name: RoleMapping[key] }
    });

    const dispatch = useDispatch();
    function fetchUsers() {

        axios.get('/api/userList').then(res => {
            setUsers(res.data);
        }).catch(e => console.log(e));
    }
    useEffect(() => {
        let cmds = [
            { name: "Resend invitation", fn: resendInvite },
            //{ name: "Delete data", fn: null },
        ];
        if(admin) {
            cmds.push({ name: "Delete", fn: userDelete });
        }
        setCommands(cmds);
        fetchUsers();

    }, []);

    function resendInvite(event, user) {
        dispatch(setStatusMessage({ msg: t("Not implemented yet"), error: true }))
    }


    function userDelete(event, user) {
        axios.delete(`/api/user/${user.id}`)
            .then(res => {
                fetchUsers();
            })
            .catch(e => {
                dispatch(setStatusMessage({ msg: t("Delete failed"), error: true }));

            });


    }

    function userOption(event, user) {
        if (!event.target.value)
            return;

        const fn = commands.filter(n => n.name === event.target.value)[0].fn;
        if (fn) {
            fn(event, user);
        }

        event.target.value = "";

    }

    function onRoleChange(user, event) {
        const key = Object.keys(RoleMapping).filter((key) => RoleMapping[key] === event.target.value)[0];

        axios.post(`/api/user/${user.id}/role`, { role: key })
            .then(() => {
                dispatch(setStatusMessage({ msg: t("Role changed") }));
                fetchUsers();
            })
            .catch((e) => {
                dispatch(setStatusMessage({ msg: t("Role change failed"), error: true }));
                console.error(e);
            })
    }
    //    <CommandButton text={t("Delete")} onClick={(ev) => onUserDelete(user.id, ev)}/>


    return (<RoutePage header={t('Manage users')}>
        <StyledTable>
            <TableHeaderElement>
                <TableHeaderCell>
                    {t("Email")}
                </TableHeaderCell>
                <TableHeaderCell>
                    {t("Company")}
                </TableHeaderCell>
                <TableHeaderCell>
                    {t("Role")}
                </TableHeaderCell>
                <TableHeaderCell >
                    <div className="text-center" >
                        {t("Commands")}
                    </div>
                </TableHeaderCell>
            </TableHeaderElement>
            <TableBodyElement>
                {users.map(user => {
                    return (
                        <TableBodyRow key={user.email}>
                            <TableBodyCell>{user.email}</TableBodyCell>
                            <TableBodyCell>{user.company.name}</TableBodyCell>
                            <TableBodyCell>
                                <DropDownSelector
                                    options={options}
                                    defaultValue={t(RoleMapping[user.role])}
                                    onSelect={(e) => onRoleChange(user, e)} className='text-wite bg-slate-800 w-[10rem]' />
                            </TableBodyCell>
                            <TableBodyCell>
                                <select className="text-white bg-slate-800" onChange={(e) => userOption(e, user)}>
                                    <option>{t("")}</option>
                                    {commands.map((row) =>

                                        <option key={row.name}>{t(row.name)}</option>
                                    )}

                                </select>

                            </TableBodyCell>
                        </TableBodyRow>
                    );
                })}
            </TableBodyElement>
        </StyledTable>
    </RoutePage>)
}

function mapStateToProps(state) {
    return {
        loggedIn: state.token !== undefined,
        admin: state.admin,
        companyAdmin: state.companyAdmin,
    };
}

export default connect(mapStateToProps)(UserManagement);
