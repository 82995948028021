import React from "react"
import StoreItem from "../components/storeItem"

export default function Store() {
  return (
    <>
      <StoreItem />
      <StoreItem />
      <StoreItem />
    </>
  )
}
