

export function epochUTC() {
    const now = new Date();
    const utcMilllisecondsSinceEpoch = now.getTime(); // + (now.getTimezoneOffset() * 60 * 1000);
    return Math.round(utcMilllisecondsSinceEpoch / 1000);
}

export function timeToUI(stamp) {
    const locale = navigator.languages && navigator.languages.length > 0 ? navigator.languages[0] : 'en-US';
    let options = {
        year: "numeric",
        month: "numeric",
        day: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: false,
    };
    const date = new Date(stamp);
    let t = new Intl.DateTimeFormat(locale, options).format(date);
    // replace possible commas so it doesn't break csv-download
    return t.replace(',', '');
}
