import React, { useEffect, useState } from "react"
import axios from "axios"
import {
    HoverTimeProvider,
    Plot,
    fromFlux,
    PlotContainer,
    exportImage,
    PlotEnv,
    timeFormatter
} from "@influxdata/giraffe"
import {
    LineChart,
    Line,
    CartesianGrid,
    XAxis,
    YAxis,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from "recharts"
import Checkbox from "./Checkbox"
import RadioButton from "./radioButton"
import { epochUTC } from "../services/util"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faL, faSpinner } from "@fortawesome/free-solid-svg-icons"
import { avgTypes } from "../routes/dashboard"


const timeSelection = ["day", "week", "month", "year"]

const colors = [
    "#4365ab",
    "#ff2142",
    "#00ff00",
    "#0000ff",
    "#ffffff",
    "#000000",
]
const apiBase = `/api/ui`

export default function SensorGraphs({ query = null, valueTypes = [], lims = null, buttonPrefix = "" }) {
    // TODO ui element to select keys
    const [config, setConfig] = useState(null)
    const [selected, setSelected] = useState([])
    const [selectedRange, setSelectedRange] = useState(timeSelection[1])
    const [fetching, setFetching] = useState(false);
    const [enableUpdate, setEnableUpdate] = useState(false);

    //const plotEnv = new PlotEnv();
    const axesCanvasRef = React.createRef();
    const layerCanvasRef = React.createRef();
    let nameMapping = {};

    useEffect(() => {
        if (!query) return
        if (!valueTypes) return

        for(let vt of valueTypes) {
            nameMapping[vt.name] = vt.label;
        }

        getData();
    }, [query, valueTypes, selected, selectedRange]);

    useEffect(() => {
        let updateHandler = setInterval(() => {
            if(enableUpdate) {
                getData();
            }
        }, 1000 * 60);
        return () => {
            clearInterval(updateHandler);
        }
    }, [enableUpdate]);

    function getData() {
        if (fetching)
            return;

        setFetching(true);

        let fields = selected;
        // TODO empty fields migh crash the graph
        // should be fixed in backend maybe... or filter some null value here?

        if (!fields.length) {
            fields = valueTypes.map(vt => vt.name);
        }

        const fieldsTxt = fields.join(",")
        let stopEpoch = epochUTC()
        let dayMul = 30
        if (selectedRange === "day") dayMul = 1
        else if (selectedRange === "week") dayMul = 7
        else if (selectedRange === "month") dayMul = 30
        else if (selectedRange === "year") dayMul = 365

        let startEpoch = stopEpoch - 60 * 60 * 24 * dayMul
        let avg = '';
        if(query.avg === avgTypes.HOUR)
            avg = '&avg=1';
        else if(query.avg === avgTypes.DAY)
            avg = '&avg=24';

        let url = `${apiBase}/device/${query.deviceMac}/lines?start=${startEpoch}&stop=${stopEpoch}${avg}`;
        if (fields.length > 0)
            url += `&fields=${fieldsTxt}`;

        axios.get(url).then((d) => {
            const { csv } = d.data;
            if (!csv || !csv.length) {
                throw new Error("Invalid data");
            }
            const lineLayer = {
                type: "line",
                x: "time",
                y: "value",
                fill: ["field"],
                position: "overlaid",                   
                //legendColumns: [],
            }
            let conf = {
                fluxResponse: csv,
                //yDomain: [0, 10],
                legendFont: "18px sans-serif",
                tickFont: "16px sans-serif",
                //onSetYDomain: function(arr) {},
                //onResetYDomain: function(arr) {},
                valueFormatters: {
                    value: (val) => `${val.toFixed(1)}`,
                    time: timeFormatter({
                        timeFormat: null,
                        format: "YYYY-MM-DD HH:mm",
                        hour12: false,
                      }),
                    field: (v) => nameMapping[v],                    
                },
                layers: [
                    {
                        type: "line",
                        x: "time",
                        y: "value",
                        fill: ["field"],
                    },
                ]
            };

            if(lims) {
                conf.yDomain = lims;
                conf.onSetYDomain = function(arr) {};
                conf.onResetYDomain = function(arr) {};
            }

            setConfig(conf);
            setFetching(false);
        }).catch(e => {
            setFetching(false);
        });
    }

    function onRefreshButton(e, id) {
        setEnableUpdate(e.target.checked);
    }

    function onTypeCheckbox(e, id) {
        let v = e.target.checked
        let obj = []
        if (!v) {
            obj = selected.filter((s) => s !== id)
        } else {
            obj = [...selected, id]
        }
        setSelected(obj)
    }

    function onTimeChange(value) {
        setSelectedRange(value.target.value.substring(buttonPrefix.length));
    }

    if (!valueTypes.length || !query) return <>
        <div className="text-center text-2xl text-cyan-500">No data to show</div>
        <div className="text-center text-lg text-white p-2">Please choose a sensor from the "Sensors" list</div>
  </>
    else
        return (
            <>
                {fetching && <FontAwesomeIcon icon={faSpinner} spin inverse style={{ position: "absolute", minHeight: 50, minWidth: 50, left: "50vw", color: "white", marginTop: "5%" }} />}

                <div className="flex text-white font-bold justify-end px-5">
                <Checkbox
                                key={buttonPrefix + "refresh"}
                                onChange={onRefreshButton}
                                id={buttonPrefix + "refresh1"}
                                text={"Enable refresh"}
                />

                    {valueTypes &&
                        valueTypes.map((vt) => (
                            <Checkbox
                                key={vt.name}
                                onChange={onTypeCheckbox}
                                id={vt.name}
                                text={vt.label ? vt.label : vt.name}
                            />
                        ))}
                </div>
                <div>
                    <ResponsiveContainer width="95%" aspect={5.0 / 1.0}>
                        <div>
                            {config && (
                                <div>
                                    <div
                                        style={{
                                            width: "calc(95vw - 20px)",
                                            height: "calc(30vh - 20px)",
                                            margin: "40px",
                                        }}
                                    >
                                        <Plot
                                            config={config}
                                            axesCanvasRef={axesCanvasRef}
                                            layerCanvasRef={layerCanvasRef}
                                        />
                                    </div>
                                </div>
                            )}
                        </div>
                    </ResponsiveContainer>
                </div>
                <div>
                    <div className="flex text-white font-bold justify-center">
                        {timeSelection.map((row) => (
                            <RadioButton
                                key={buttonPrefix + row}
                                onChange={onTimeChange}
                                group={buttonPrefix + "graphOption"}
                                name={`${buttonPrefix}${row}`}
                                labelText={row}
                            />
                        ))}
                    </div>
                </div>
                <div className="my-auto mx-5 flex justify-end">
                    <button
                        onClick={() => {
                            if (layerCanvasRef.current && axesCanvasRef.current) {
                                exportImage(layerCanvasRef.current, axesCanvasRef.current, {
                                }).then(image => window.open(image, '_blank'))
                            }
                        }}

                        className="rounded-full px-4 py-2 text-center text-slate-300 bg-cyan-800 hover:text-white hover:bg-cyan-700">
                        EXPORT IMAGE
                    </button>
                </div>
            </>
        )
}
