const Table = ({ children }) => (
    <div className="flex flex-col">
        <div className="overflow-x-auto">
            <div className="p-1.5 w-full inline-block align-middle">
                <div className="border rounded-lg">
                    <table className="min-w-full divide-y divide-gray-200">
                        {children}
                    </table>
                </div>
            </div>
        </div>
    </div>
);

const TableHeaderCell = ({ children }) => (
    <th
        scope="col"
        className="px-6 py-3 text-xs font-bold"
    >
        {children}
    </th>
);

const TableHeaderElement = ({ children }) => (
    <thead className="bg-gray-50 text-gray-500 uppercase">
        <tr>{children}</tr>
    </thead>
);

const TableBodyElement = ({ children }) => (
    <tbody className="divide-y divide-gray-200 text-white">{children}</tbody>
);

const TableBodyCell = ({children, cellKey}) => (
    <td className="px-6 py-4 text-sm  whitespace-nowrap">{children}</td>
);

const TableBodyRow = ({children, rowKey}) => (
<tr key={rowKey}>{children}</tr>
);
export { TableHeaderCell, TableHeaderElement, TableBodyElement, TableBodyCell, TableBodyRow };
export default Table;