import React, { useState } from "react"
import { Navigate, useNavigate } from "react-router-dom"
import axios from "axios"
import {
  tokenSet,
  setAdmin,
  setCompanyAdmin,
  setTwoFactorAuth,
  setStatusMessage,
} from "../redux/store"
import { useDispatch, connect } from "react-redux"
import TwoFactorValidationModal from "../components/authentication/validate2Fa"
import i18next from "i18next"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faEye,
  faEyeSlash,
  faUserSecret,
} from "@fortawesome/free-solid-svg-icons"

function ValidateEmail(input) {
  var validRegex =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
  return !input.match(validRegex)
}

function Login(props) {
  const isLoggedIn = props.loggedIn
  let [twoFactorModal, setTwoFactorModal] = useState(null)
  const [showPassword, setShowPassword] = useState(false)
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const loginDataReceived = (data) => {
    const { token, adminRole, companyAdminRole, twoFactorEnabled } = data
    dispatch(tokenSet(token))
    localStorage.setItem(
      "token_data",
      JSON.stringify({ token, adminRole, companyAdminRole })
    )
    dispatch(setAdmin(adminRole))
    dispatch(setCompanyAdmin(companyAdminRole))
    dispatch(setTwoFactorAuth(twoFactorEnabled))
    navigate("/dashboard")
  }

  function resetPassword(e) {
    e.preventDefault()
    const login = document.getElementById("username").value
    if (ValidateEmail(login)) {
      dispatch(
        setStatusMessage({ msg: i18next.t("Invalid email"), error: true })
      )
      return
    }
    console.log(login)
    axios
      .post("/api/passwordReset", { login })
      .then((d) => {
        console.log(d)
        dispatch(setStatusMessage({ msg: i18next.t("Send reset email") }))
      })
      .catch((e) => {
        console.error(e)
        dispatch(setStatusMessage({ msg: i18next.t("Error"), error: true }))
      })
  }
  function togglePassword() {
    setShowPassword(!showPassword)
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    const login = document.getElementById("username").value
    const password = document.getElementById("current-password").value

    if (!login || !password) return

    axios
      .post("/api/login", { username: login, password })
      .then((res) => {
        const {
          user: { token, twoFactorEnabled },
        } = res.data
        if (twoFactorEnabled) {
          // Not yet in, token is temporary and will only fit to 2FA key validation
          setTwoFactorModal({ token })
        } else {
          loginDataReceived(res.data.user)
        }
      })
      .catch((err) => console.error(err.toString()))
  }

  //TODO Re-direct user to the dashboard view upon login completion

  return (
    <div className="flex w-screen justify-center text-white" style={{marginTop: "10vw"}}>
      <div className="w-2/5 flex justify-center ">
        <div className="text-center ">
          <form onSubmit={handleSubmit} method="post">
            <section>
              <p className="text-2xl text-left text-cyan-600">
                {i18next.t("Email")}
              </p>
             <div className="py-1">
             <input
                className="text-black rounded-lg py-1 px-3"
                id="username"
                name="username"
                type="text"
                autoComplete="username"
                required
                autoFocus
              />
             </div>
            </section>
            <section>
              <p className="text-2xl text-left text-cyan-600">
                {i18next.t("Password")}
              </p>
              <div className="flex relative">
                <div className="py-1">
                  <input
                    className="text-black rounded-lg py-1 px-3"
                    id="current-password"
                    name="password"
                    type={showPassword ? "text" : "password"}
                    autoComplete="current-password"
                    required
                  />
                </div>
                <div>
                  <FontAwesomeIcon
                    onClick={togglePassword}
                    className="text-black  absolute right-2 bottom-3"
                    style={{ cursor: "pointer" }}
                    icon={showPassword ? faEyeSlash : faEye}
                  />
                </div>
                <div></div>
              </div>
            </section>
            <div className="flex-col p-2">
              <div className="my-3 bg-cyan-900">
                <button
                  className=" hover:bg-slate-700 text-white font-bold p-2 h-full w-full"
                  type="submit"
                >
                  {i18next.t("Login")}
                </button>
              </div>
              <div className="my-3">
                <button
                  className=" hover:text-cyan-500 text-white p-2 h-full w-full underline"
                  onClick={resetPassword}
                >
                  {i18next.t("Reset password")}
                </button>
              </div>
            </div>
          </form>
          {twoFactorModal && (
            <TwoFactorValidationModal
              requestToken={twoFactorModal.token}
              loginSuccess={loginDataReceived}
              closeModal={() => {
                setTwoFactorModal(null)
              }}
            />
          )}
        </div>
      </div>
    </div>
  )
}

function mapStateToProps(state) {
  return {
    loggedIn: state.token !== undefined,
  }
}

export default connect(mapStateToProps)(Login)
