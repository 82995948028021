import React from 'react'

export default function Support() {
  return (
    <div className="flex w-screen justify-center p-5">
        <div className="text-white text-center w-2/5">
          <h1 className="text-3xl p-3">FAQ</h1>
          <h2 className="text-2xl p-3">What does air quality index mean?</h2>
          <p>
            An air quality index (AQI) is used by government agencies to
            communicate to the public how polluted the air currently is or how
            polluted it is forecast to become. AQI information is obtained
            by averaging readings from an air quality sensor, which can increase
            due to vehicle traffic, forest fires, or anything that can increase
            air pollution. Pollutants tested include ozone, nitrogen dioxide,
            sulphur dioxide, among others.
          </p>
          <h1 className="text-3xl p-3">Contact us</h1>
          <p>admin@senhitu.com</p>
          <p>+358 05050505</p>
        </div>
      </div>
  )
}
