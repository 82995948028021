import React, { useState } from 'react';

export default function DropDownSelector({ options, selectElementId, defaultValue = '', className = 'text-white bg-slate-800 w-[18rem]', onSelect = null }) {

    const [selectedValue, setSelectedValue] = useState(defaultValue);
    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        if (onSelect)
            onSelect(event);

    };
    if (options.length < 1) {
        return null;
    }

    return (

        <select id={selectElementId} className={className} value={selectedValue} onChange={handleChange}>
            {options.map((option) => {
                let { value } = option;
                if (!value) {
                    value = option.name;
                }
                return (
                    <option key={option.name} value={value}>
                        {option.name}
                    </option>
                )
            })}
        </select>

    );
};