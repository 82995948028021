// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".yT3fs1x-Z87WKN9OkKAKDQ\\=\\={cursor:pointer}.yT3fs1x-Z87WKN9OkKAKDQ\\=\\=:hover{opacity:80%;transition:opacity .25s ease}", "",{"version":3,"sources":["webpack://./src/components/Annotation/AnnotationLine.scss"],"names":[],"mappings":"AAKA,4BACE,cAAA,CACA,kCACE,WAAA,CACA,4BAAA","sourcesContent":["/*\n  Annotation Line Styles\n  ------------------------------------------------------------------------------\n*/\n\n.giraffe-annotation-hover {\n  cursor: pointer;\n  &:hover {\n    opacity: 80%;\n    transition: opacity 0.25s ease;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"giraffe-annotation-hover": "yT3fs1x-Z87WKN9OkKAKDQ=="
};
export default ___CSS_LOADER_EXPORT___;
