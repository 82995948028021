export default function Home() {
    return (
        <div>
            <div className="text-white text-center">
                <h1 className="text-3xl p-5">
                    Making particle monitoring mainstream</h1>
                    <div className="w-1/2 text-center m-auto">
                <p>


                    The aerosol sensor model offered by Senhitu is a reliable solution for air quality monitoring in various industries. This high-quality sensor is suitable for both portable and stationary use, making it a versatile option for on-the-go measurements or long-term monitoring. With our sensor, you can obtain accurate, real-time measurements of air quality to make informed decisions and promote healthier environments. Our team is committed to delivering a dependable and efficient sensor that meets the needs of our customers.
                </p>
                </div>
            </div>
        </div>
    )
}
