import React, { useEffect, useState } from 'react'
import { useDispatch, connect } from 'react-redux';
import axios from 'axios';
import TwoFactorAuth from '../components/authentication/setup2FaAuth';
import { setStatusMessage, setTwoFactorAuth, setConf } from '../redux/store';
import { CompanyInfo } from '../components/CompanyInfo';
import { useTranslation } from 'react-i18next';

export function Profile({ admin, twoFactorEnabled }) {

    let [openModality, setOpenModality] = useState(false);

    function msgError(msg) {
        dispatch(setStatusMessage({ msg: msg, error: true }));
    }
    function msgSuccess(msg) {
        dispatch(setStatusMessage({ msg: msg, error: false }));
    }


    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();

    function setUserConf() {
        let conf = {};
        axios.put('/api/user', conf).then(() => {
            dispatch(setConf(conf));
        }).catch((e) => {
            console.error(e);
            msgError(t("Update failed"));
        })
    }

    function disable2FA() {
        axios.post('/api/authentication/disableOtp').then(() => {
            dispatch(setTwoFactorAuth(false));
        }).catch(err => {
            connect.log(err);
        })
    }
    function changePassword() {
        const pw = document.getElementById("passwordField").value;
        document.getElementById("passwordField").value = "";
        axios.post("/api/password", { password: pw })
            .then(() => {
                msgSuccess(t("Password changed"));
            })
            .catch((e) => {
                msgError(t("Password change failed"));

            });
    }


    return (
        <div>
            <div className="flex w-screen p-5 text-white">
                <div className="w-4/5 flex justify-center">
                    <div className="">
                        <h1 className="text-3xl pb-3">{t("Profile overview")}</h1>
                    </div>
                    <div className="mx-10">
                        <div className="my-5">
                            <label style={{ marginRight: 20 }}>
                                {t("Change password ")}
                            </label>
                            <input id="passwordField"
                                type="password"
                                style={{ marginRight: 20 }}
                                className="text-black"
                            />
                            <button style={{ marginRight: 20 }}
                                type="button"
                                className="bg-slate-800 hover:bg-slate-700 font-medium text-sm p-3 mb-2" onClick={changePassword}
                            >
                                {t('Change password')}
                            </button>

                            {twoFactorEnabled ? <button
                                type="button"
                                className="bg-slate-800 hover:bg-slate-700 font-medium text-sm p-3 mb-2" onClick={disable2FA}
                            >
                                {t('Disable 2FA')}
                            </button> :
                                <button
                                    type="button"
                                    className="bg-slate-800 hover:bg-slate-700 font-medium text-sm p-3 mb-2" onClick={() => setOpenModality(true)}
                                >
                                    {t('Enable 2FA')}
                                </button>
                            }
                        </div>
                        {(openModality) && <TwoFactorAuth closeModal={() => setOpenModality(false)} />}
                    </div>

                </div>
            </div>
            <CompanyInfo />
        </div>
    )
}


function mapStateToProps(state) {
    return {
        admin: state.admin,
        loggedIn: state.token !== undefined,
        twoFactorEnabled: state.twoFactorEnabled,
    };
};

export default connect(mapStateToProps)(Profile);