import { useEffect, useState } from "react";
import axios from "axios";
import QRCode from "qrcode";
import i18next from "i18next";
import { useDispatch } from 'react-redux';
import CommandButton from "../live/commandButton";
import { ModalPage } from "./twoFactorModal";
import { setTwoFactorAuth } from '../../redux/store';
import CodeInput, { InputId } from "./codeInput";

const styles = {
  heading3: `text-xl font-semibold text-gray-900 p-4 border-b`,
  heading4: `text-base text-ct-blue-600 font-medium border-b mb-2`,

  buttonBlue: `text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800`,
  buttonGrey: `text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-blue-300 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600`,
  inputField: `bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-2/5 p-2.5`,
};




const TwoFactorAuth = ({
  closeModal,
}) => {
  const [qrcodeUrl, setqrCodeUrl] = useState("");
  const [pendingOtp, setPendingOtp] = useState(null);
  const dispatch = useDispatch();
  function verifyWithCode() {
    const code = document.getElementById(InputId).value;
    axios.post(`/api/authentication/verifyOtp`, { token: code }).then(() => {
      dispatch(setTwoFactorAuth(true));
      closeModal();
    }).catch(err => console.log(err));
  }


  useEffect(() => {
    if (!pendingOtp) {

      axios.post(`/api/authentication/generateOtp`).then(res => {
        const { url } = res.data;
        setPendingOtp({ url });
      });

    }
  }, [pendingOtp]);
  if (pendingOtp) {

    QRCode.toDataURL(pendingOtp.url).then(setqrCodeUrl);
  }


  return (
    <ModalPage>
      <div className="relative rounded-lg shadow">
        <h3 className={styles.heading3}>{i18next.t("Two-Factor Authentication (2FA)")} </h3>
        {/* Modal body */}
        <div className="p-6 space-y-4">
          <h4 className={styles.heading4}>
            {i18next.t("Configuring Google Authenticator")}

          </h4>
          <p className="text-sm">
            {i18next.t('Install Google Autheticator and use it to scan following code:')}

          </p>
          <div>
            <h4 className={styles.heading4}>{i18next.t("Scan QR Code")}</h4>
            <div className="flex justify-center">
              <img
                className="block w-64 h-64 object-contain"
                src={qrcodeUrl}
                alt="qrcode url"
              />
            </div>
          </div>

          <div>
            <h4 className={styles.heading4}>{i18next.t("Verify Code")}</h4>
            <p className="text-sm">
              {i18next.t("For changing the setting, please verify the authentication code:")}

            </p>
            <CodeInput verifyWithCode={verifyWithCode} />

          </div>
        </div>
      </div>
      <div className="flex justify-between">
        <div className="w-1/4" >
          <CommandButton text={i18next.t("Cancel")} onClick={closeModal} />
        </div>
        {pendingOtp &&
          <div className="w-1/4 left-0" >
            <CommandButton text={i18next.t("Verify")} onClick={verifyWithCode} />
          </div>
        }
      </div>
    </ModalPage>
  );
};

export default TwoFactorAuth;